<script>
  import { fly } from 'svelte/transition';
  import { writable } from 'svelte/store';
  import InkWell from 'ui/inkwell.svelte';
  import { without } from 'lodash';

  export let title = "Select";
  export let options = [];
  export let value = [];
  

  // Input value is bound to `textValue` store. 

  let textValue = writable("");


  // When an option is selected, 
  // - set the `value` to report to any binding parent,
  // - set the `textValue` for display,
  function select(option) {
    if(!value) {
      value = [];
    }
    if(value.includes(option)) {
      value = without(value, option);
    }
    else {
      value = [...value, option];
    }
    textValue.set(value.join(', '));
  }


  // As the user types, update the value with any matched options.
  textValue.subscribe(textValue => {
    let strings = textValue.split(',').map(s => s.trim().toLowerCase());
    value = options.filter(option => strings.includes(option.toLowerCase()));
  });


  // The menu should be open whenever the cursor is in the field.

  let isFocused = false;
  $: isMenuOpen = isFocused;
  



</script>


<div class="container" class:withValue="{!!$textValue}">
  <input type="text" bind:value="{$textValue}" on:focus="{() => isFocused = true}" on:blur="{() => isFocused = false}"/>
  <p class="title">{title}</p>
  <div class="rule">
    <div class="rule-color"></div>
  </div>

  {#if isMenuOpen}
  <div class="menu" transition:fly="{{y:-10}}" on:mousedown="{e => e.preventDefault()}">
      {#each options as option}
        <div class="button" class:active="{value?.includes(option)}" on:click="{() => select(option)}">
          {option}
          <InkWell/>
        </div>
      {/each}
  </div>
  {/if}

</div>
<div class="hint">
  <slot name="hint"></slot>
</div>



<style>
  .container {
    position: relative;
    background-color: var(--greyLight);
  }

  input {
    flex: 1;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 8px 11px;
    border: 0;
    margin: 0;

    appearance: none;
    background-color: transparent;

    font-size: 16px;
    line-height: 18px;
  }

  .title {
    position: absolute;
    top: 3px;
    left: 8px;
    max-width: calc(100% - 16px);
    font-size: 16px;
    line-height: 18px;
    color: var(--textLight);
    pointer-events: none;
    transition: all ease-in-out 100ms;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .container.withValue .title,
  input:focus ~ .title {
    top: 0px;
    font-size: 10px;
    line-height: 13px;
  }

  .rule {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;

    display: flex;
    justify-content: center;
  }

  .rule-color {
    width: 0%;
    height: 100%;
    background-color: var(--grey);
    transition: all ease-in-out 200ms;
  }

  input:focus ~ .rule .rule-color {
    width: 100%;
  }

  .hint {
    box-sizing: border-box;
    min-height: 16px;
    padding: 2px 0 0 0;

    font-size: 11px;
    line-height: 13px;
    color: var(--textLight);
  }

  .menu {
    position: absolute;
    top: 50px;
    left: 8px;
    min-width: 200px;
    max-height: 200px;
    overflow-y: auto;
    background-color: var(--greyLight);
    box-shadow: var(--depth2);
    z-index: 10;
  }

  .menu .button {
    position: relative;
    padding: 0 16px;
    font-size: 15px;
    line-height: 40px;
    color: var(--text);
    cursor: pointer;
  }

  .menu .button.active {
    background-color: var(--accent);
    color: white;
  }

  .menu .button:not(.active) :global(em) {
    background-color: var(--accentLight);
  }
</style>