<script>
  import firebase from 'firebase/app';
  import 'firebase/firestore';
  import Loading from 'ui/loading.svelte';
  import {match} from 'utils/router.js'; 
  import query from 'query/index.js';

  let {packId, itemId, talentId} = match('/opt-in/:packId/:itemId/:talentId');

  $: data = query(`{
    item(packId:$packId, itemId:$itemId) {
      subject
    }
  }`, {packId, itemId});

  if(packId && itemId && talentId) {
    firebase.firestore()
      .doc(`packages/${packId}/items/${itemId}/talents/${talentId}`)
      .set({date:Date.now()});
  }

</script>


{#if !$data}
  <Loading/>
{:else}
  <section>
    <h1>You have opted in.</h1>
    <p>You have opted in to {$data?.item.subject}.</p>
    <div class="icon">
      <i class="fas fa-theater-masks"></i>
    </div>
  </section>
{/if}


<style>

  section {
    /* background-color: white;
    padding: 24px 32px 40px; */

    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 40px auto;
    /* box-shadow: var(--depth1); */
  }

  h1 {
    font-size: 32px;
    color: var(--accent);
    padding: 0 0 16px 0;
    margin: 0;
  }

  p {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }

  .icon {
    position: absolute;
    top: 0;
    right: 8px;
    z-index: -1;
    opacity: 0.4;
    font-size: 240px;
    line-height: 1.2em;
    color: var(--grey);
    transform: rotate(10deg);
    filter: drop-shadow(-1px -1px 1px rgba(0,0,0,0.2)) drop-shadow(1px 1px 1px white);
  }
</style>


