<script>
  export let type = "text";
  export let title = "";
  export let value = "";
</script>


<div>
  <div class="container" class:withValue="{!!value}">
    {#if type == 'text'}
      <input type="text" bind:value="{value}" on:focus on:blur on:change on:input/>
    {:else if type == 'password'}
      <input type="password" bind:value="{value}" on:focus on:blur on:change on:input/>
    {/if}
    <p class="title">{title}</p>
    <div class="rule">
      <div class="rule-color"></div>
    </div>
  </div>
  <div class="hint">
    <slot name="hint"></slot>
  </div>
</div>

<style>
  .container {
    position: relative;
    background-color: var(--greyLight);
  }

  input {
    flex: 1;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 8px 11px;
    border: 0;
    margin: 0;

    appearance: none;
    background-color: transparent;

    font-size: 16px;
    line-height: 18px;
  }

  .title {
    position: absolute;
    top: 3px;
    left: 8px;
    max-width: calc(100% - 16px);
    font-size: 16px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--textLight);
    pointer-events: none;
    transition: all ease-in-out 100ms;
  }

  .container.withValue .title,
  input:focus ~ .title {
    top: 0px;
    font-size: 10px;
    line-height: 13px;
  }

  .rule {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;

    display: flex;
    justify-content: center;
  }

  .rule-color {
    width: 0%;
    height: 100%;
    background-color: var(--grey);
    transition: all ease-in-out 200ms;
  }

  input:focus ~ .rule .rule-color {
    width: 100%;
  }

  .hint {
    box-sizing: border-box;
    min-height: 16px;
    padding: 2px 0 10px 0;

    font-size: 11px;
    line-height: 13px;
    color: var(--textLight);
  }
</style>