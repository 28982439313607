import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';


/**
 * @param {Object} args 
 * @param {String} args.agencyId
 * @param {Boolean} args.expired If true, expired notices are included.
 */

export function notices(args) {
  if(!args.agencyId) {
    return of(null);
  }
  return new Observable(observer => {

    let handler = snap => {
      let data = snap.val() || {};
      let res = [];
      
      for(let key in data) {
        res.push({
          noticeId: key,
          agencyId: args.agencyId,
          ...data[key],
        });
      }
      observer.next(res);
    };

    let ref = firebase.database().ref(`notices/${args.agencyId}`);

    // TODO: do we want to add any filters in there? 

    if(args.expired !== true) {
      ref = ref.orderByChild('dateExpires').startAt(Date.now());
    }

    ref.on('value', handler);
    return () => ref.off('value', handler);
  });
}