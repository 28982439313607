<script>
  import InkWell from 'ui/inkwell.svelte';
  import { onMount } from 'svelte';

  export let title = "";
  export let value = "";
  export let showRule = true;
  
  let isBold = false;
  let isItalic = false;
  let isUnderline = false;

  let input;

  export function focus() {
    input.focus();
  }

  // When a user pastes content into a text area, we extract just
  // the plain text. Stops all the word nonsense from coming through.

  function paste(e) {
    e.preventDefault();
    let content;
    if(e.clipboardData) {
      content = e.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, content);
    }
    else if(window.clipboardData) {
      content = window.clipboardData.getData('text/plain');
      document.selection.createRange().pasteHTML(content);
    }
  }

  // Manage bold, italic, and underline command states. 
  // Keep internal state in sync with browser 
  // when an action button is pressed and
  // when the selection changes. 

  function updateActionStates() {
    isBold = document.queryCommandState("bold");
    isItalic = document.queryCommandState("italic");
    isUnderline = document.queryCommandState("underline");
  };
  
  function bold() {
    document.execCommand('bold', false);
    updateActionStates();
  }

  function italic() {
    document.execCommand('italic', false);
    updateActionStates();
  }

  function underline() {
    document.execCommand('underline', false);
    updateActionStates();
  }

  onMount(() => {
    document.addEventListener('selectionchange', updateActionStates);
    return () => document.removeEventListener('selectionchange', updateActionStates);
  });
  
</script>

<div class="container" class:withValue="{!!value}">

  <div 
    contenteditable="true" 
    class="input" 
    bind:innerHTML={value}
    on:paste="{paste}"
    bind:this="{input}"
  />

  <p class="title">{title}</p>
  {#if showRule}
    <div class="rule">
      <div class="rule-color"></div>
    </div>
  {/if}
  <div class="actions" on:mousedown="{e => e.preventDefault()}">
    <div class="button" class:active="{isBold}" on:click="{bold}">
      <i class="fas fa-bold"></i>
      <InkWell/>
    </div>
    <div class="button" class:active="{isItalic}" on:click="{italic}">
      <i class="fas fa-italic"></i>
      <InkWell/>
    </div>
    <div class="button" class:active="{isUnderline}" on:click="{underline}">
      <i class="fas fa-underline"></i>
      <InkWell/>
    </div>
    <!-- <div class="button" on:mousedown="{e => e.preventDefault()}">
      <i class="fas fa-text-size"></i>
      <InkWell/>
    </div> -->
  </div>
</div>
<div class="hint">
  <slot name="hint"></slot>
</div>


<style>
  .container {
    position: relative;
    background-color: var(--greyLight);
  }

  .input {
    flex: 1;
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 100px;
    padding: 24px 8px 11px;
    border: 0;

    appearance: none;
    background-color: transparent;

    font-size: 16px;
    line-height: 18px;
  }

  .title {
    position: absolute;
    top: 3px;
    left: 8px;
    font-size: 16px;
    line-height: 18px;
    color: var(--textLight);
    pointer-events: none;
    transition: all ease-in-out 100ms;
  }

  .container.withValue .title,
  .input:focus ~ .title {
    top: 0px;
    font-size: 10px;
    line-height: 13px;
  }

  .rule {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;

    display: flex;
    justify-content: center;
  }

  .rule-color {
    width: 0%;
    height: 100%;
    background-color: var(--grey);
    transition: all ease-in-out 200ms;
  }

  .input:focus ~ .rule .rule-color {
    width: 100%;
  }

  .hint {
    box-sizing: border-box;
    min-height: 16px;
    padding: 2px 0 10px 0;

    font-size: 11px;
    line-height: 13px;
    color: var(--textLight);
  }

  .actions {
    position: absolute;
    top: -8px;
    right: 8px;
    display: none;
    box-shadow: var(--depth1);
  }

  .input:focus ~ .actions {
    display: flex;
  }

  .actions .button {
    position: relative;
    display: inline-block;
    width: 32px;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    color: var(--textLight);
    background-color: var(--grey);
    cursor: pointer;
  }

  .actions .button:hover {
    color: var(--text);
  }

  .actions .button.active {
    color: var(--accent);
  }
</style>