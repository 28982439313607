<script>
  import Loading from 'ui/loading.svelte';
  import Title from 'ui/title.svelte';
  import TitleButton from 'ui/title-button.svelte';
  import Button from 'ui/button.svelte';
  import AddModal from './routes/new/index.svelte';
  import ViewModal from './routes/view/index.svelte';
  import query from 'query/index.js';
  import router, { push } from 'utils/router.js';
  import { url } from 'utils/cloudinary.js';
  
  let route = router('/notices/:id');
  
  let data = query(`{
    user {
      userId
      agencyId
      notices
      permissions {
        canPack
      }
      activeTalent {
        agencyId
        notices
      }
    }
  }`);

  $: notices = $data?.user.notices || $data?.user.activeTalent.notices;

  // Keep track of the selected notice. 

  $: selected = $route.id && notices && notices.find(n => n.noticeId == $route.id);
  

  // If a user can pack, allow them to post.

  $: canPost = $data?.user.permissions.canPack;

</script>



<!-- 
The Title Bar
With an Add button for agents. 
And tabs for users with multiple agencies.
-->

<Title>
  Bulletin Board
  <div slot="actions">
    {#if $data?.user.permissions.canPack}
      <TitleButton href="/notices/new" tooltip="Add Notice">
        <span><i class="far fa-plus"></i></span>
      </TitleButton>  
    {/if}
  </div>
</Title>



{#if !$data}

  <Loading/>


<!-- 
If no notices, show placeholder content.
-->

{:else if !notices?.length}

  <div class="empty">
    <div class="content">
      {#if canPost}
        <p>The bulletin board is a good place to share general information with your talent.</p>
        <p>When you post a notice, all talent will be sent an email, and will see the notice on their bulletin board.</p>
        <nav>
          <a href="/notices/new">
            <Button accent="{true}">Add Notice</Button>
          </a>
        </nav>
      {:else}
        <p>There are no notices right now.</p>
        <p>Watch this space for information from your agent.</p>
      {/if}
    </div>
    <div class="icon">
      <i class="fas fa-thumbtack"></i>
    </div>
  </div>


<!-- 
Display notice card for each notice.
-->

{:else}
  <div class="notices-list">
    {#each notices.reverse() as notice (notice.noticeId)}

      <a class="notice" href="/notices/{notice.noticeId}">
        {#if notice.cover}
          <div class="cover">
            <img src="{notice.cover.urls.small}" alt="Cover"/>
          </div>
        {/if}
        <p class="subject">{notice.subject}</p>
        <div class="message-wrapper">
          <div class="message">
            {@html notice.body}
          </div>
        </div>
        {#each notice.attachments || [] as attachment}
          <img src="{url(attachment, {width:40, height:60, format:'png'})}" alt="Attachment" class="attachment"/>
        {/each}
      </a>
    {/each}
  </div>
{/if}



<!-- 
If /new, show the post notice modal.
-->

{#if $route.id == "new"}

  <AddModal on:close="{() => push('/notices')}"/>

{:else if $route.id}

  <ViewModal notice="{selected}" on:close="{() => push('/notices')}"/>

{/if}





<style> 
  .empty {
    position: relative;
    padding: 0 8px;
  }

  .empty .icon {
    position: absolute;
    top: 0;
    right: 8px;
    z-index: -1;
    opacity: 0.4;
    font-size: 240px;
    line-height: 16px;
    color: var(--grey);
    transform: rotate(10deg);
    filter: drop-shadow(-1px -1px 1px rgba(0,0,0,0.2)) drop-shadow(1px 1px 1px white);
  }

  .empty p {
    font-size: 18px;
    line-height: 20px;
    color: var(--textLight);
    max-width: 480px;
  }

  .empty p:last-of-type {
    margin-bottom: 40px;
  }

  nav {
    display: flex;
  }

  .notices-list {
    margin: 16px 8px 40px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  .notice {
    display: block;
    background-color: white;
    box-shadow: var(--depth1);
  }

  .notice .cover {
    position: relative;
  }

  .notice .cover img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }

  .notice .subject {
    padding: 8px;
    margin: 0;
    font-size: 16px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .notice .message-wrapper {
    padding: 0 8px 8px;
  }

  .notice .message {
    position: relative;
    font-size: 13px;
    line-height: 16px;
    box-sizing: border-box;
    max-height: calc(6 * 16px);
    overflow: hidden;
    margin: 0;
  }

  .notice .message::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 16px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }

  .notice .attachment {
    margin: 0 0 8px 8px;
  }

  @media (min-width: 900px) {
    .empty {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      max-width: 800px;
      margin: 80px auto;
    }

    .empty .icon {
      position: static;
      opacity: 1;
    }

    .empty .content {
      margin-right: 80px;
    }

    .notices-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin: 24px 60px 40px;
    }
  }

  @media (min-width: 1200px) {
    .notices-list {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  @media (min-width: 1500px) {
    .notices-list {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }
</style>