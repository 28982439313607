<script>
  import firebase from 'firebase/app';
  import 'firebase/database';
  import InputText from 'ui/input-text.svelte';
  import InkWell from 'ui/inkwell.svelte';
  import Loading from 'ui/loading.svelte';


  let isLoading = false;


  // Login Form

  let email = "";
  let error = "";
  let isSending = false;
  let isSent = false;

  async function submit(e) {
    e.preventDefault();

    if(isSending) {
      return;
    }

    isSending = true;
    try {
      await firebase.auth().sendPasswordResetEmail(email);
    }
    catch(e) {
      console.error(e);
      error = e.message;
      isSending = false;
      return;
    }

    isSending = false;
    isSent = true;
  }

</script>



<section>
  <form on:submit="{submit}">
    {#if isLoading} 
      <Loading/>
    {:else if !isSent}
      <img src="/img/logo-red.png" alt="Skybolt"/>
      <p class="welcome">Please enter your email address below to reset your password.</p>
      <InputText title="Email Address" bind:value="{email}"/>
      <p class="error">{error}</p>
      <button type="submit" class="button">
        {#if isSending}
          HANG TIGHT...
        {:else}
          RESET PASSWORD
        {/if}
        <InkWell/>
      </button>
      <div class="link">
        <a href="/login">Remember it now? Log in here.</a>
      </div>
      <div class="link">
        <a href="/register">Not a member? Register here.</a>
      </div>
    {:else} 
      <img src="/img/logo-red.png" alt="Skybolt"/>
      <p class="welcome">Password reset instructions have been sent to {email}.</p>
      <a class="button" href="/login">BACK TO LOGIN <InkWell/></a>
    {/if}
  </form>
</section>



<style>
  section {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(/img/onboarding-background.jpg);
    background-position: center;
    background-size: cover;
  }

  form {
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    min-height: 100%;
    padding: 24px 16px;
    background-color: white;
    text-align: center;
  }

  img {
    max-width: 200px;
    max-height: 120px;
  }

  .welcome {
    color: var(--textLight);
    margin-bottom: 32px;
  }

  .error {
    color: var(--error, #ff0000);
  }

  .button {
    display: inline-block;
    position: relative;
    appearance: none;
    width: 160px;
    text-align: center;
    padding: 12px 0px;
    margin-bottom: 40px;
    background-color: var(--accent);
    color: white;
    border: 0;
    border-radius: 2px;
    box-shadow: var(--depth1);
    cursor: pointer;
  }

  .link {
    display: block;
    width: 100%;
    padding-bottom: 4px;
    text-align: left;
  }

  .link a {
    color: var(--textLight);
  }

  .link a:hover {
    color: var(--accent);
  }

  @media (min-width:800px) {
    form {
      min-height: auto;
      padding: 40px 80px;
      border-radius: 2px;
      box-shadow: var(--depth3);
    }
  }
</style>