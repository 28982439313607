import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';

/**
 * 
 * @param {Object} args 
 * @param {String} args.packId
 * @param {String} args.itemId
 */
export function item(args) {

  if(!args.packId || !args.itemId) {
    return of(null);
  }

  return new Observable(observer => {

    let ref = firebase.firestore().doc(`packages/${args.packId}/items/${args.itemId}`);
    let handler = doc => {
      observer.next({
        itemId: args.itemId,
        packId: args.packId,
        ...doc.data(),
      });
    };

    return ref.onSnapshot(handler);

  });
}