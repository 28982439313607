import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';


/**
 * Query a single package. 
 * 
 * @param {Object} args 
 * @param {String} args.packId The id of the package to load.
 * @param {Array<String>} args.access An array of access tokens.
 */
export function pack(args) {
  if(!args.packId) {
    return of(null);
  }
  return new Observable(observer => {
    return firebase.firestore().doc(`packages/${args.packId}`).onSnapshot(doc => {
      observer.next({packId:doc.id, ...doc.data()});
    });
  });
}