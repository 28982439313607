import firebase from 'firebase/app';
import 'firebase/auth';
import { emit } from 'utils/events.js';

export default async function login(email, password) {
  const res = await firebase.auth().signInWithEmailAndPassword(email, password);

  let userId = res.user.uid;
  let user = await firebase.database().ref(`users/${userId}`).once('value').then(s => s.val());
  
  emit("LOGIN", {userId, user});
  return {userId, ...user};
}