import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';

export function clients(args) {
  if(!args.agencyId) {
    return of([]);
  }

  let ref = firebase.firestore()
    .collection(`agencies/${args.agencyId}/clients`)
    .orderBy('name', 'asc');

  if(args.limit) {
    ref = ref.limit(args.limit);
  }

  return new Observable(observer => {
    return ref.onSnapshot(querySnapshot => {
        let res = [];
        querySnapshot.forEach((doc) => {
          res.push({
            clientId: doc.id,
            ...doc.data()
          });
        });
        observer.next(res);
      })
  });
}