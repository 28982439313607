<script>
  import InkWell from 'ui/inkwell.svelte';
  import { push } from 'utils/router.js';
  export let href = null;
  export let tooltip = "";
</script>

<div class="link" on:click on:mouseenter on:mouseleave on:click="{() => href && push(href)}">
  <div class="icon"><slot></slot></div>
  <InkWell/>
  {#if tooltip}
    <aside>{tooltip}</aside>
  {/if}
</div>

<style>
  .link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: 4px;
    background-color: var(--grey);
    transition: background-color 100ms ease-in-out;
    cursor: pointer;
  }

  .link:hover {
    background-color: var(--accent);
  }

  .icon {
    color: white;
    font-size: 20px;
    padding-top: 3px;
  }

  aside {
    position: absolute;
    top: 100%;
    z-index:100000;
    border-radius: 4px;
    margin-top: 12px;
    pointer-events: none;

    white-space: nowrap;
    font-size: 13px;
    padding: 4px 8px;
    color: var(--text);
    background-color: var(--grey);
    box-shadow: var(--depth2);

    opacity: 0;
    transform: translateY(-10px);
    transition: all 100ms ease-in-out;
  }

  aside::before {
    content: "";
    background-color: var(--grey);
    width: 8px;
    height: 8px;
    position: absolute;
    top: -4px;
    left: calc(50% - 4px);
    transform: rotate(45deg);
    z-index: -1;
  }

  .link:hover > aside {
    opacity: 1;
    transform: translateY(0px);
    transition: all 200ms ease-in-out 1s;
  }


</style>