<script>
  import { cubicOut } from 'svelte/easing';
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  export let title = "";


  let state = false;
  export function send() {
    state = 'sent';
    dispatch('close');
  }

  export function close() {
    dispatch('close');
  }

  function fly(node) {
    const style = window.getComputedStyle(node);
    const target_opacity = +style.opacity;
    const transform = style.transform === 'none' ? '' : style.transform;

    return {
      delay: 0,
      duration: 400,
      easing: cubicOut,
      css: (t, u) => `
        transform: ${transform} translate(0px, ${(1 - t) * (state == 'sent' ? -200 : 60)}px);
        opacity: ${target_opacity - (target_opacity * u)};
      `
    };
  }

  

</script>

<div class="wrapper" on:click="{() => dispatch('close')}" transition:fly>
  <div class="content" on:click="{e => e.stopPropagation()}">
    {#if title}
      <h1>{title}</h1>
    {/if}
    <slot></slot>
    <div class="close" on:click="{() => dispatch('close')}"><i class="fal fa-times"></i></div>
  </div>
</div>


<style>

  .wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 1000;
    
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .content {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 900px;
    min-height: 100%;
    padding-bottom: 50vh;
    
    background-color: white;
    box-shadow: var(--depth2);
  }

  h1 {
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
    color: var(--textLight);
    padding: 16px 0 16px 16px;
    margin: 0;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px 18px;

    font-size: 18px;
    color: var(--textLight);
    background-color: white;
    cursor: pointer;
  }

  @media (min-width: 900px) {
    .wrapper {
      padding: 80px 16px 10%;
    }

    .content {
      min-height: 200px;
      border-radius: 2px;
      padding: 0;
    }

    .close {
      position: absolute;
      border-radius: 0 2px 0 2px;
    }
  }

</style>