import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';

/**
 * 
 * @param {Object} args 
 * @param {String} args.activeTalent
 */
export function activeTalent(args) {

  if(!args.activeTalent) {
    return of(null);
  }

  return new Observable(observer => {

    let ref = firebase.database().ref(`talents/${args.activeTalent}`);
    let handler = snap => {
      observer.next({
        talentId: args.activeTalent,
        ...snap.val(),
      });
    };

    ref.on('value', handler);
    return () => ref.off('value', handler);

  });
}