<script>
  import { fly } from 'svelte/transition';
  import Input from 'ui/input-html.svelte';
  import InkWell from 'ui/inkwell.svelte';
  import query from 'query/index.js';
  import { onMount } from 'svelte';
  import { url, upload } from 'utils/cloudinary.js';
  import replyToNotice from 'actions/reply-to-notice.js';

  export let noticeId;
  
  let data = query(`{
    user {
      userId
      activeTalent {
        talentId
        image
        name: fullName
      }
    }
  }`);
  

  // Handle attachments. 
  // Populating an `attachments` field.
  
  let attachments = [];
  let fileInput;

  function addAttachments(e) {
    for(let file of e.target.files) {
      let attachment = {
        progress: 1,
        date: Date.now(),
      };
      attachments = [...attachments, attachment];

      upload(file, {
        folder: "attachments",
        onProgress: ({progress}) => {
          attachment.progress = progress;
          attachments = attachments; // for svelte
        },
        onComplete: (res) => {
          attachment.progress = null;
          attachment.cloudinary_id = res.cloudinary_id;
          attachment.type = res.type;
          attachment.format = res.format;
          attachments = attachments; // for svelte
        }
      });
    }
  }

  function removeAttachment(attachment) {
    attachments = attachments.filter(a => a != attachment);
  }



  // Handle sending the reply message.

  let error = "";
  let body;
  let bodyInput;
  onMount(() => bodyInput.focus());

  let isSending = false;
  let isSent = false;

  async function send() {
    if(isSending) return;
    isSending = true;

    try {
      await replyToNotice({
        noticeId,
        talentId: $data.user.activeTalent.talentId,
        message: {
          from: {
            name: $data.user.activeTalent.name,
            talentId: $data.user.activeTalent.talentId,
            userId: $data.user.userId,
          },
          body,
          attachments,
        }
      });
    }
    catch(e) {
      error = e.message;
      isSending = false;
      return;
    }
    
    isSent = true;
  }



</script>


{#if !isSent}

  <div class="reply" in:fly="{{y:-10, duration:400, delay:100}}">
    <div class="from">
      <img src="{url($data?.user.activeTalent.image, {width:60, height:60, face:true})}" alt="from"/>
    </div>
    <div class="input">
      <Input title="Reply" showRule="{false}" bind:value="{body}" bind:this="{bodyInput}"/>
    </div>
  </div>

  <div class="attachments">
    {#each attachments as attachment}
      {#if attachment.progress}
        <div class="attachment">
          <i class="fad fa-spinner-third fa-spin"></i>
          <p>{attachment.progress}%</p>
          <div class="remove" on:click="{() => removeAttachment(attachment)}">
            <i class="fas fa-times-circle"></i>
          </div>
        </div>
      {:else}
        <div class="attachment">
          <img src="{url(attachment, {width:80, height:100})}" alt="Attachment Preview"/>
          <div class="remove" on:click="{() => removeAttachment(attachment)}">
            <i class="fas fa-times-circle"></i>
          </div>
        </div>
      {/if}
    {/each}
  </div>

  {#if error} 
    <p class="error">{error}</p>
  {/if}

  <nav in:fly="{{y:-20, duration:400, delay:200}}">
    <div class="attachment-button" on:click="{() => fileInput.click()}">
      <i class="fas fa-paperclip"></i> <span>Add Attachments</span>
    </div>
    <div class="space"></div>
    <div class="send-button" on:click="{send}">
      <i class="fas fa-paper-plane"></i> 
      &nbsp; 
      {isSending ? "Sending..." : "Send"}
      <InkWell/>
    </div>
  </nav>

  <input type="file" bind:this="{fileInput}" multiple on:change="{addAttachments}"/>

{:else}

  <div class="sent" in:fly="{{y:16, duration:400}}">
    Your message has been sent.
  </div>

{/if}



<style>

  .reply {
    padding: 0 8px 0 0;
    margin: 0 16px 16px;
    background-color: var(--greyLight);
  }

  .reply .from {
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    position: relative;
    overflow: visible;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin: 12px 10px 0 10px;
    background-color: var(--grey);
  }

  .reply .from img {
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }

  .reply .input {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 60px);
  }

  .attachments {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 0 0 16px;
  }

  .attachment {
    position: relative;
    width: 80px;
    height: 100px;
    background-color: var(--grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 16px 16px 0
  }

  .attachment .remove {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
  }

  .attachment:hover .remove {
    display: block;
  }

  .error {
    padding: 0 0 16px 16px;
    margin: 0;
    color: var(--error);
  }

  nav {
    display: flex;
    align-items: center;
    padding: 0 16px 16px;
  }

  nav .attachment-button {
    color: var(--textLight);
    cursor: pointer;
    user-select: none;
    padding: 4px 16px 0 0;
    line-height: 50px;
  }

  nav .attachment-button:hover {
    color: var(--textDark);
  }

  nav .attachment-button span {
    display: none;
  }

  nav .space {
    flex: 1;
  }

  nav .send-button {
    position: relative;
    box-sizing: border-box;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 18px 0 16px;
    border-radius: 2px 0 2px 0;
    
    font-size: 16px;
    line-height: 54px;
    color: var(--textLight);
    background-color: var(--greyLight);
    cursor: pointer;
  }

  nav .send-button:hover {
    color: var(--text);
  }

  .sent {
    color: white;
    background-color: var(--accent);
    box-shadow: var(--depth1);
    
    padding: 8px 16px;
    border-radius: 2px;
    margin: 0 16px 16px;
  }

  input[type=file] {
    display: none;
  }

  @media (min-width: 900px) {
    nav .attachment-button span {
      display: inline-block;
    }
  }

</style>