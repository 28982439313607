let subscribers = window.__route_subscribers = window.__route_subscribers || [];

function matchAllSubscribers() {
  for(let sub of subscribers) {
    sub.callback(
      match(sub.path, sub.exact)
    );
  }
}

export function push(url, state={}) {
  window.history.pushState(state, "", url + window.location.search);
  matchAllSubscribers();
}

export function replace(url, state={}) {
  if(!url) {
    url = window.location.pathname;
  }
  window.history.replaceState(state, "", url + window.location.search);
  matchAllSubscribers();
}

export async function pop() {
  window.history.back();
}

export function matchPath(url, path, exact=false) {
  if(!path) {
    return {match:true, url};
  }
  var res, m, c, o, tmp, ext, keys=[], pattern='', arr = path.split('/');
  arr[0] || arr.shift();
  while (tmp = arr.shift()) {
    c = tmp[0];
    if (c === '*') {
      keys.push('wild');
      pattern += '/(.*)';
    } else if (c === ':') {
      o = tmp.indexOf('?', 1);
      ext = tmp.indexOf('.', 1);
      keys.push( tmp.substring(1, !!~o ? o : !!~ext ? ext : tmp.length) );
      pattern += !!~o && !~ext ? '(?:/([^/]+?))?' : '/([^/]+?)';
      if (!!~ext) pattern += (!!~o ? '?' : '') + '\\' + tmp.substring(ext);
    } else {
      pattern += '/' + tmp;
    }
  }
  pattern = new RegExp('^' + pattern + (!exact ? '(?=$|\/)' : '\/?$'), 'i');
  m = pattern.exec(window.location.pathname);
  if(!m) {
    return {match:false};
  }
  res = {match:true, path, url:m[0]};
  for(let i=0; i<keys.length; i+=1) {
    res = {...res, [keys[i]]: m[i+1]};
  }
  return res;
}

export function match(path, exact) {
  return matchPath(window.location.pathname, path, exact);
}

export default function(path, exact=false) {
  const sub = {path, exact};
  return {
    subscribe(callback) {
      sub.callback = callback;
      subscribers.push(sub);
      callback( match(path, exact) );
      return function unsubscribe() {
        subscribers = subscribers.filter(s => s != sub);
      };
    },
    set(value) {
      sub.value = value;
    }
  }
}

function linkHandler(e) {
  let el = e.target;
  do {
    if(el.tagName === "A" && el.href && !el.target) {
      e.preventDefault();
      let url = new URL(el.href);
      push(url.pathname);
      break;
    }
    el = el.parentElement;
  } while(el);
}

export function attach(node) {
  if(node.getAttribute('skybolt-attached')) {
    return;
  }
  node.setAttribute('skybolt-attached', 'true');
  node.addEventListener('click', linkHandler);
}

export function detach(node) {
  node.removeEventListener('click', linkHandler);
}

window.addEventListener('popstate', () => {
  matchAllSubscribers();
});