import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, combineLatest, of, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';


function subscribeToTalent(id) {
	return new Observable(observer => {
		const ref = firebase.database().ref(`talents/${id}`);
		const handler = snapshot => {
			observer.next({
				...snapshot.val(), 
				talentId:snapshot.key
			});
		};
		ref.on('value', handler);
		return () => ref.off('value', handler);
	});
}


/**
 * Load/Search for Talent
 * @param {Object} args 
 * @param {String} args.talents An object of talents.
 * @param {String} args.status 
 * @param {Number} args.limit
 */
export function talents(args) {
  let talentIds$ = of([]);

  if(args.talents) {
		talentIds$ = of(Object.keys(args.talents));
	}

  return talentIds$
		.pipe(
			mergeMap(ids => {
				if(!ids.length) {
					return of([]);
				}
				const talents$ = ids.map(subscribeToTalent);
				return combineLatest(talents$);
			}),
			map(talents => {
				if(args.status == "ACTIVE") {
					talents = talents.filter(t => t.status == "PAID" || t.status == "TRIAL");
				}
				else if(args.status) {
					talents = talents.filter(t => t.status == args.status);
				}
				return talents;
			})
		);
}