const fields = {
  firstName: {
    name: "First Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isRequired: true,
    isRemovable: false,
    order: -6,
  },
  middleName: {
    name: "Middle Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    order: -5,
  },
  lastName: {
    name: "Last Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isRequired: true,
    isRemovable: false,
    order: -4,
  },
  contactName: {
    name: "Contact Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isRemovable: false,
    order: -3,
  },
  dob: {
    name: "Date of Birth",
    type: "date",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isRequired: true,
    isRemovable: false,
    order: -1,
  },
  bicep: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: false,
    isVisibleOnCard: false,
    placement: "info",
    max: "24",
    min: 8,
    name: "Bicep",
    type: "number",
    units: "in.",
    order: 1
  },
  braCup: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    multiple: true,
    name: "Bra Cup",
    options: [ "AA", "A", "B", "C", "D", "E", "V" ],
    type: "select",
    order: 2,
    condition: {
      field: "gender",
      value: "Female"
    },
  },
  bust: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 52,
    min: 15,
    name: "Bust",
    type: "number",
    units: "in.",
    order: 3,
    condition: {
      field: "gender",
      value: "Female"
    },
  },
  contractExp: {
    category: "Legal",
    isEditableByTalent: false,
    isPublic: false,
    isVisibleOnCard: true,
    isExpiration: true,
    placement: "meta",
    name: "Contract Exp.",
    type: "date",
    order: 4
  },
  dress: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 18,
    min: 1,
    name: "Dress",
    type: "range",
    order: 5,
    condition: {
      field: "gender",
      value: "Female"
    },
  },
  ethnicity: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    multiple: true,
    name: "Ethnicity",
    options: [ "African-American", "Asian", "Caucasian", "Indian", "Latin American", "Middle Eastern", "Hispanic", "Filipino", "Eurasian", "Samoan" ],
    type: "select",
    order: 6
  },
  eye: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    isVisibleOnCard: true,
    placement: "info",
    multiple: true,
    name: "Eye",
    options: [ "Blue", "Blue Green", "Brown", "Green", "Hazel" ],
    type: "select",
    order: 7
  },
  facebook: {
    category: "Social Media",
    isEditableByTalent: true,
    isPublic: true,
    name: "Facebook URL",
    type: "text",
    order: 8
  },
  gender: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    name: "Gender",
    options: [ "Male", "Female" ],
    type: "select",
    order: 9
  },
  hair: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    isVisibleOnCard: true,
    placement: "info",
    multiple: true,
    name: "Hair",
    options: [ "Auburn", "Bald", "Black", "Blonde", "Brown", "Grey", "Light Brown", "Red", "Salt & Pepper", "Strawberry Blonde" ],
    type: "select",
    order: 10
  },
  height: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isFeet: true,
    isPublic: true,
    isVisibleOnCard: true,
    placement: "info",
    max: 95,
    min: 12,
    name: "Height",
    type: "number",
    units: "in.",
    order: 11
  },
  highHips: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 52,
    min: 15,
    name: "High Hips",
    type: "number",
    units: "in.",
    order: 12
  },
  hips: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 52,
    min: 15,
    name: "Hips",
    type: "number",
    units: "in.",
    order: 13
  },
  inseam: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 36,
    min: 8,
    name: "Inseam",
    type: "number",
    units: "in.",
    order: 14
  },
  instagram: {
    category: "Social Media",
    isEditableByTalent: true,
    isPublic: true,
    name: "Instagram URL",
    type: "text",
    order: 15
  },
  neck: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 18,
    min: 14,
    name: "Neck",
    type: "number",
    units: "in.",
    order: 16
  },
  playsAge: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 60,
    min: 0,
    name: "Plays Age",
    type: "range",
    units: "years",
    order: 17
  },
  shoe: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 14,
    min: 1,
    name: "Shoe",
    type: "number",
    order: 18
  },
  size: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    isVisibleOnCard: true,
    placement: "info",
    multiple: true,
    name: "Size",
    options: [ "3 MO", "6 MO", "9 MO", "12 MO", "18 MO", "24 MO", "2T", "2", "3T", "3", "4T", "4", "5", "6", "6X", "7", "8", "10", "12", "14", "16", "18", "20", "JR" ],
    type: "select",
    order: 19
  },
  sleeve: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 38,
    min: 22,
    name: "Sleeve",
    type: "number",
    units: "in.",
    order: 20
  },
  suit: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 52,
    min: 33,
    name: "Suit",
    type: "number",
    order: 21,
    condition: {
      field: "gender",
      value: "Male"
    }
  },
  thigh: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 24,
    min: 8,
    name: "Thigh",
    type: "number",
    units: "in.",
    order: 22
  },
  twitter: {
    category: "Social Media",
    isEditableByTalent: true,
    isPublic: true,
    name: "Twitter URL",
    type: "text",
    order: 23
  },
  unions: {
    category: "Legal",
    isEditableByTalent: true,
    isPublic: true,
    isVisibleOnCard: true,
    placement: "meta",
    multiple: true,
    name: "Unions",
    options: [ "SAG", "AFTRA", "SAG Eligible", "non-union", "AFTRA Eligible", "Fi-Core", "AEA", "AGVA", "AGMA" ],
    type: "select",
    order: 24
  },
  waist: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 52,
    min: 15,
    name: "Waist",
    type: "number",
    units: "in.",
    order: 25
  },
  weight: {
    category: "Physical Attributes",
    isEditableByTalent: true,
    isPublic: true,
    max: 300,
    min: 0,
    name: "Weight",
    type: "number",
    units: "lbs.",
    order: 26
  },
  workExp: {
    category: "Legal",
    isEditableByTalent: false,
    isPublic: false,
    isVisibleOnCard: true,
    placement: "meta",
    name: "Work Permit Exp.",
    type: "date",
    isExpiration: true,
    order: 27
  }
};


export const requiredFields = Object.keys(fields)
  .map(fieldId => ({fieldId, ...fields[fieldId]}))
  .filter(field => field.isRequired)
  .sort((a, b) => a.order - b.order);
  

export const searchFields = Object.keys(fields)
  .map(fieldId => ({fieldId, ...fields[fieldId]}))
  .filter(field => field.isSearchable !== false);


export default fields;