import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

function currentUserId() {
  return new Observable(observer => {
    return firebase.auth().onAuthStateChanged(user => {
      if(!user || user.isAnonymous) {
        observer.next(null);
      }
      else {
        observer.next(user.uid);
      }
    });
  });
}

function watchUser(uid) {
  if(!uid) {
    return of(null);
  }
  return new Observable(observer => {
    let ref = firebase.database().ref(`users/${uid}`);
    let handler = snap => {
      observer.next({...snap.val(), userId:uid});
    };
    ref.on('value', handler);
    return () => ref.off('value', handler);
  })
}

export function user() {
  return currentUserId().pipe(
    switchMap(watchUser)
  );
}