<script>
  let well = null;
  function click(e) {
    let ripple = document.createElement("span");

    ripple.setAttribute('class', 'ui-inkwell-ripple');
    ripple.style.top = e.layerY + 'px';
    ripple.style.left = e.layerX + 'px';

    well.appendChild(ripple);
    setTimeout(() => well && well.removeChild(ripple), 600);
  }
</script>

<div bind:this="{well}" on:click="{click}"></div>

<style>
  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  @keyframes -global-uiinkwellripple {
    0% { 
      width: 0px;
      height: 0px; 
      transform: translate(-0px, -0px);
      opacity: 1;
    }
    100% { 
      width: 200px;
      height: 200px;
      transform: translate(-100px, -100px);
      opacity: 0;
    }
  }

  :global(.ui-inkwell-ripple) {
    position: absolute;
    width: 0px;
    height: 0px;
    background-color: rgba(255,255,255,0.4);
    animation: uiinkwellripple 600ms ease-in;
    border-radius: 50%;
    pointer-events: none;
  }
</style>