import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';

/**
 * 
 * @param {Object} args 
 * @param {String} args.packId
 * @param {String} args.agencyId
 * @param {String} args.userId
 * @param {String} args.email 
 */
export function items(args={}) {

  if(!args.packId) {
    return of(null);
  }

  return new Observable(observer => {

    let access = [args.agencyId, args.userId, args.email].filter(a => !!a);

    return firebase.firestore()
      .collection(`packages/${args.packId}/items`)
      .where('access', 'array-contains-any', access)
      .orderBy("dateUpdated", "desc")
      .onSnapshot(snap => {
        let items = [];
        snap.forEach(doc => {
          items.push({
            itemId:doc.id, 
            packId: args.packId,
            ...doc.data()
          });
        });
        observer.next(items);
      });

  });

}
