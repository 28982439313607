import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import algoliasearch from 'algoliasearch';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';



async function searchPacks(args) {
  let filters = [];

  let userId = firebase.auth().currentUser?.uid;
  if(!userId) {
    return [];
  }
  filters.push(`access: ${userId}`);

  let agencyId = await firebase.database().ref(`users/${userId}/agencyId`).once('value').then(s => s.val());
  filters.push(`access: ${agencyId}`);

  let email = await firebase.database().ref(`users/${userId}/email`).once('value').then(s => s.val());
  filters.push(`access: ${email}`);


  // DEPRICATED 
  // All access ids should be put in `access` array so we can query with 
  // a firestore array contains any filter. -->
  filters.push(`userId:${userId}`);
  if(agencyId) {
    filters.push(`agencyId:${agencyId}`);
    filters.push(`recipients.agencyId:${agencyId}`);
  }
  if(email) {
    filters.push(`recipients.email:${email}`);
  }
  // <-- DEPRICATED


  filters = filters.join(" OR ");
  
  const search = {
    offset: 0,
    length: args.limit || 50, 
    filters: filters,
  };
  
  let res = await algoliasearch("ALGOLIA_APP", "ALGOLIA_SEARCH_KEY").initIndex('packs').search(args.search, search);

  let packs = res.hits.map(hit => {
    return {
      packId: hit.objectID,
      name: hit.name,
      highlighted: {
        name: hit._highlightResult?.name?.value
      },
    };
  });

  return packs;
}

function user(userId) {
  return new Observable(observer => {
    let handler = snap => observer.next({userId, ...snap.val()});
    let ref = firebase.database().ref(`users/${userId}`);
    ref.on('value', handler);
    return () => ref.off('value', handler);
  });
}

function userPacks(user, limit=50) {
  let access = [user.userId];
  if(user.email) {
    access.push(user.email);
  }

  if(user.permissions?.canPack && user.agencyId) {
    access.push(user.agencyId);
  }

  return new Observable(observer => {
    return firebase.firestore()
      .collection('packages')
      .where('access', 'array-contains-any', access)
      .orderBy('dateUpdated', 'desc')
      .limit(limit)
      .onSnapshot(querySnapshot => {
        let res = [];
        querySnapshot.forEach((doc) => {
          res.push({
            packId: doc.id,
            ...doc.data()
          });
        });
        observer.next(res);
      })
  });
}

function accessPacks(access, limit=50) {
  return new Observable(observer => {
    return firebase.firestore()
      .collection('packages')
      .where('access', 'array-contains', access)
      .orderBy('dateUpdated', 'desc')
      .limit(limit)
      .onSnapshot(querySnapshot => {
        let res = [];
        querySnapshot.forEach((doc) => {
          res.push({
            packId: doc.id,
            ...doc.data()
          });
        });
        observer.next(res);
      })
  });
}


/**
 * Query packages. 
 * 
 * @param {Object} args 
 * @param {String} args.search
 * @param {Number} args.limit 
 * @param {String} args.access
 */
export function packs(args) {

  // If a `search` is given, 
  // find all packages that match that search.
  
  if(args.search) {
    return searchPacks(args);
  }


  // If there's a `userId` argument, 
  // load all packages where that user's email address is in the package's `access` array, 
  // or if the user has the `canPack` permission, 
  // load all packages where that user's agencyId is the in package's `access` array.

  if(args.userId) {
    return user(args.userId).pipe(
      switchMap(user => userPacks(user, args.limit))
    )
  }


  // If an arbitrary access token is given,
  // load all packages with that access.

  if(args.access) {
    return accessPacks(args.access, args.limit);
  }

  return of([]);

}

