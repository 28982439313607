<script>
  import firebase from 'firebase/app';
  import 'firebase/database';
  import { fly } from 'svelte/transition';
  import register from 'actions/register.js';
  import Button from 'ui/button.svelte';
  import InputText from 'ui/input-text.svelte';
  import Loading from 'ui/loading.svelte';
  import { match, push } from 'utils/router.js';
  import { setTheme } from 'utils/theme.js';
  import { url } from 'utils/cloudinary.js';
  import baseTerms from './terms.html';
  import { writable } from 'svelte/store';
  import _ from 'lodash';


  let isLoading = false;
  let hasSeenTerms = false;

  let screen = "FORM";

  const urlParams = new URLSearchParams(window.location.search);
  const division = urlParams.get('division');


  // If an agency is supplied, theme the form accoringly. 

  let {agencyId, invitationId} = match('/register/:agencyId?/:invitationId?');
  agencyId = agencyId?.toLowerCase() || null;
  
  let logo = "/img/logo-red.png";
  let registerText = "";
  let terms = baseTerms;

  if(agencyId) {
    isLoading = true;
    firebase.database().ref(`agencies/${agencyId}`).once('value').then(snap => {
      let agency = snap.val();
      if(!agency) {
        isLoading = false;
        return;
      }
      if(agency.theme) {
        setTheme(agency.theme);
      }
      /* Depricated */
      if(agency.accentColor) {
        setTheme({
          accent: agency.accentColor,
        })
      }
      if(agency.logo) {
        logo = url(agency.logo, {height:120});
      }
      if(agency.registerText) {
        registerText = agency.registerText;
      }
      isLoading = false;
    });
  }



  // Form
  // Preload in inviation if given.

  let value = writable({});
  let error = "";
  let isRegistering = false;

  if(invitationId) {
    firebase.database().ref(`invitations/${invitationId}`).once('value').then(snap => {
      let invitation = snap.val();
      $value.firstName = invitation.user.firstName;
      $value.lastName = invitation.user.lastName;
      $value.email = invitation.user.email;
    });
  }

  async function next() {
    if(!hasSeenTerms) {
      screen = "TERMS";
      hasSeenTerms = true;
      return;
    }
    
    if(isRegistering) return;
    isRegistering = true;
  
    try {
      await register({
        agencyId,
        invitationId,
        division,
        ...$value
      });
    }
    catch(e) {
      console.error(e);
      error = e.message;
      isRegistering = false;
      screen = "FORM";
      return;
    }

    push('/');
  }

</script>



<section>
  {#if isLoading}
    
    <div class="wrapper">
      <Loading/>
    </div>

  {:else if screen == "FORM"} 

    <div class="wrapper" out:fly="{{x:-30}}">
      <div class="card">
        <img src="{logo}" alt="Skybolt"/>
        <p class="welcome">{registerText}</p>
        <InputText title="Billing First Name" bind:value="{$value.firstName}"/>
        <InputText title="Billing Last Name" bind:value="{$value.lastName}"/>
        <InputText title="Email Address" bind:value="{$value.email}"/>
        <InputText type="password" title="Password" bind:value="{$value.password}"/>
        <InputText type="password" title="Confirm Password" bind:value="{$value.confirmPassword}"/>
        <p class="error">{error}</p>
        <nav>
          <Button accent on:click="{next}">
            {#if isRegistering} 
              <span><i class="fad fa-spinner-third fa-spin"></i></span>
            {:else}
              REGISTER
            {/if}
          </Button>
        </nav>
        <div class="link">
          <a href="/login/{agencyId || ""}">Already a member? Log in here.</a>
        </div>
      </div>
    </div>

  {:else}

    <div class="wrapper" in:fly="{{x:30}}">
      <div class="card">
        <img src="{logo}" alt="Skybolt"/>
        <div class="terms">
          {@html terms}
        </div>
        <nav>
          <Button accent on:click="{next}">
            {#if isRegistering} 
              <span><i class="fad fa-spinner-third fa-spin"></i></span>
            {:else}
              ACCEPT
            {/if}
          </Button>
        </nav>
      </div>
    </div>

  {/if}
</section>



<style>
  section {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-image: url(/img/onboarding-background.jpg);
    background-position: center;
    background-size: cover;
  }

  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: auto;
  }

  .card {
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    min-height: 100%;
    padding: 24px 16px;
    background-color: white;
    text-align: center;
  }

  img {
    max-width: 200px;
    max-height: 120px;
    margin-bottom: 16px;
  }

  .welcome {
    color: var(--textLight);
    padding: 0;
    margin: 0 0 32px;
  }

  .error {
    color: var(--error, #ff0000);
    text-align: left;
    padding: 0 0 16px;
    margin: 0;
  }

  .terms {
    color: var(--text);
    background-color: var(--greyLight);
    max-height: 371px;
    overflow: auto;
    padding: 8px;
    margin: 0 0 16px 0;
  }

  nav {
    display: flex;
    justify-content: center;
  }

  .link {
    display: block;
    width: 100%;
    padding-bottom: 4px;
    text-align: left;
  }

  .link a {
    color: var(--textLight);
  }

  .link a:hover {
    color: var(--accent);
  }

  @media (min-width:800px) {
    .card {
      min-height: auto;
      padding: 40px 80px;
      border-radius: 2px;
      box-shadow: var(--depth3);
    }
  }
</style>