import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import AuthError from 'errors/auth.js';


/**
 * Load a draft. 
 * 
 * @param {Object} args
 * 
 * @param {String} args.draftId 
 * An identifier for the draft.
 * 
 * @throws {AuthError} 
 * Thows an auth error if the user is not logged in.
 */
export default function loadDraft({draftId}) {
  const user = firebase.auth().currentUser;
  if(!user || user.isAnonymous) {
    throw new AuthError();
  }
  return firebase.database().ref(`drafts/${user.uid}/${draftId}`).once('value').then(s => s.val());
}