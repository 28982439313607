<script>
  import InputSearch from 'ui/input-search.svelte';
  import { getContext } from 'svelte';
  import { writable } from 'svelte/store';
  import { debounce } from 'lodash';

  export let title = "Image";
  export let value;
  
  let images = [];
  let query = writable("");
  let page = 1;
  let pages;
  let isSearching = false;

  const searchUnsplash = async () => {
    if(!$query) return;
    let res = await fetch(`https://api.unsplash.com/search/photos?client_id=z7YOMG2pDWMVbGghSULOgI3IjqO7edo_6PeCmNl_8vY&query=${$query}&orientation=landscape&page=${page}&per_page=20`)
    let json = await res.json();
    images = json.results;
    pages = json.total_pages;
    isSearching = false;
  };

  const debouncedSearchUnsplash = debounce(searchUnsplash, 1000);

  const nextPage = () => {
    page = Math.min(pages, page + 1);
    searchUnsplash();
  }

  const prevPage = () => {
    page = Math.max(1, page - 1);
    searchUnsplash();
  }

  query.subscribe(str => {
    page = 1;
    images = [];
    isSearching = !!str;
    debouncedSearchUnsplash();
  });

  const select = image => {
    value = image;
  }

  const clear = () => {
    value = null;
  }

</script>

<div class="container">

  {#if value} 

    <p class="title">{title}</p>
    <div class="preview">
      <img src="{value.urls.small}" alt="{value.description}"/>
      <a class="caption" href="{value.links.html}" target="_blank">Photo by {value.user.name} on Unsplash</a>
      <div class="clear" on:click="{clear}">
        <i class="fas fa-times-circle"></i>
      </div>
    </div>

  {:else}

    <InputSearch title="{title}" bind:value={$query}/> 

    {#if isSearching}
      <p class="loading"><i class="fas fa-spinner-third fa-spin"></i> Searching...</p>
    {/if}
    
    <div class="options">
      {#each images as image (image.id)}
        <img class="option" src="{image.urls.thumb}" alt="{image.description}" on:click="{() => select(image)}"/>
      {/each}
    </div>

    {#if images.length}
      <div class="page-button" on:click="{prevPage}">
      <i class="fas fa-arrow-left"></i> Previous
      </div>
    {/if}

    {#if images.length}
      <div class="page-button" on:click="{nextPage}">
        Next <i class="fas fa-arrow-right"></i>
      </div>
    {/if}

  {/if}

</div>


<style>
  .container {
    margin-bottom:16px;
  }

  .title {
    font-size: 16px;
    line-height: 16px;
    color: var(--textLight);

    padding: 0;
    margin: 0 0 8px 0;
  }

  .preview {
    position: relative;
    width: 100%;
    max-width: 600px;
  }

  .preview img {
    width: 100%;
  }

  .preview .caption {
    display: block;
    position: absolute;
    bottom: 8px;
    left: 8px;
    color: white;
    text-shadow: 0 0 4px rgba(0,0,0,0.4);
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .preview .clear {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 8px;
    color: white;
    font-size: 16px;
    filter: drop-shadow(0 0 4px rgba(0,0,0,0.4));
    cursor: pointer;
  }

  .options {
    /* make some room for the last option on each line to extend its
    padding beyond the modal bounds */
    margin-right: -8px;
  }

  .option {
    display: inline-block;
    /* modal width - modal padding - gaps / 10 */
    width: calc((900px - 32px - 9 * 8px) / 10);
    height: calc((900px - 32px - 9 * 8px) / 10);
    object-fit: cover;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
  }

  .page-button {
    display: inline-block;
    margin-right: 8px;
    line-height: 13px;
    cursor: pointer;
    padding: 8px 16px;
    color: var(--textLight);
  }

  .loading {
    color: var(--textLight);
  }

</style>