import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { Observable } from 'rxjs';
import AuthError from 'errors/auth.js';
import ValidationError from 'errors/validation.js';

/**
 * Get a draft for a given id.
 * 
 * @param {Object}  args
 * @param {String}  args.draftId
 * 
 * @throws {AuthError} Thows an auth error if the user is not logged in, or does
 * not have permission to create spotlights.
 * 
 * @throws {Error} ID argument is required.
 * 
 * @returns {Observable<Object>}
 */
export function draft(args) {
  const user = firebase.auth().currentUser;
  if(!user || user.isAnonymous) {
    throw new AuthError();
  }
  
  if(!args.draftId) {
    throw new ValidationError("draftId")
  }
  
  return new Observable(observer => {
    const ref = firebase.database().ref(`drafts/${user.uid}/${args.draftId || args.id}`);
    const handler = snap => {
      if(!snap.exists()) {
        observer.next({});
        return;
      }
      observer.next(snap.val());
    };
    ref.on('value', handler);
    return () => ref.off('value', handler);
  });
}