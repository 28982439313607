<script>
  import InkWell from './inkwell.svelte';
  import moment from 'moment';
  import { times } from 'lodash';
  import { createEventDispatcher } from 'svelte';

  export let value;
  let dispatch = createEventDispatcher();

  $: menuDate = moment(value, 'x').isValid() ? moment(value, 'x') : moment();
  function prevMonth() {
    menuDate = menuDate.subtract(1, 'month');
  }

  function nextMonth() {
    menuDate = menuDate.add(1, 'month');
  }

  function select(date) {
    value = date;
    dispatch("change", value);
  }
</script>

<div class="menu" on:mousedown="{e => e.preventDefault()}">
  <div class="month-wrapper">
    <div class="prev-month" on:click="{prevMonth}"><i class="far fa-angle-left"></i><InkWell/></div>
    <div class="month">{menuDate.format("MMMM, YYYY")}</div>
    <div class="next-month" on:click="{nextMonth}"><i class="far fa-angle-right"></i><InkWell/></div>
  </div>
  <div class="days-wrapper">
    <span>M</span>
    <span>T</span>
    <span>W</span>
    <span>T</span>
    <span>F</span>
    <span>S</span>
    <span>S</span>
  </div>
  <div class="date-wrapper">
    {#each times(moment(menuDate).date(1).isoWeekday()-1) as i}
      <span></span>
    {/each}
    {#each times(moment(menuDate).daysInMonth()) as i}
      <span 
        class:active="{moment(menuDate).date(i+1).isSame(value, 'day')}"
        class:today="{moment(menuDate).date(i+1).isSame(Date.now(), 'day')}"
        on:click="{() =>  select(moment(menuDate).date(i+1).valueOf())}">
        {i+1}<InkWell/>
      </span>
    {/each}
  </div>
</div>


<style>
  .month-wrapper {
    display: flex;
    align-items: center;
  }

  .month-wrapper .prev-month,
  .month-wrapper .next-month {
    flex: 0;
    position: relative;
    padding: 16px;
    cursor: pointer;
  }

  .month-wrapper .month {
    flex: 1;
    text-align: center;
  }

  .days-wrapper {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 0 4px 4px 4px;
  }

  .days-wrapper span {
    text-align: center;
    color: var(--textLight);
  }

  .date-wrapper {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 0 4px 16px 4px;
  }

  .date-wrapper span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin: 4px;
    cursor: pointer;
  }

  .date-wrapper span.active {
    background-color: var(--accent);
    color: white;
  }

  .date-wrapper span.today {
    background-color: var(--grey);
  }
</style>