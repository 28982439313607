import firebase from 'firebase/app';
import 'firebase/database';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import defaultTalentFields from 'utils/talent-fields.js';
import { isObject, isEmpty, get, isArray, min, max } from 'lodash';
import moment from 'moment';


/**
 * Get talent 'info' fields. These are fields flagged as appearing
 * on cards, but not flagged as "metadata".
 * 
 * @param {Object} args
 * @param {Boolean} [args.public] Only return fields flagged as public by the agency.
 * @param {String} args.talentId
 * @param {String} [args.placement] Either 'info' or 'meta'
 * 
 * @returns {Observable<Array>} Returns an array of talent field objects, each object
 * has properties for `fieldId`, `category`, `name`, `value`, and `valid`.
 */
export function talentInfo(args) {
  
  // If not talentId is given, return an empty array.
  
  if(!args.talentId) {
    return of([]);
  }
  
  
  // If the talent has an agency, load that agencies talent fields.
  
  let fields$;
  if(args.agencyId) {
    fields$ = new Observable(observer => {
      const ref = firebase.database().ref(`agencies/${args.agencyId}/talentFields`);
      const handler = snap => {
        if(!snap.exists()) {
          observer.next({});
        }
        observer.next(snap.val() || {});
      };
      ref.on('value', handler);
      return () => ref.off('value', handler);
    });
  }
  else {
    fields$ = of(defaultTalentFields);
  }
  
  
  // Build fields array.
  
  return fields$.pipe(map(fields => {
    return Object.keys(fields)
      .map(fieldId => ({fieldId, ...fields[fieldId]}))
      .sort((a, b) => a.order < b.order ? -1 : 1)
      .map(field => {
        const fieldId = field.fieldId;
        
        // Only use fields that are set as visible on cards, 
        // - that are valid values on the talent,
        // - aren't flagged as "meta" fields,
        // - aren't "system" fields.
    	  if(!field.isVisibleOnCard) {
    	    return null;
    	  }
				if(args.placement && field.placement !== args.placement) {
    	    return null;
    	  }
    	  if(!args[fieldId] || (isObject(args[fieldId]) && isEmpty(args[fieldId]))) {
    	    return null;
    	  }
    	  if(args.public && !field.isPublic) {
    	    return null;
    	  }
    	  if(field.category === 'system') {
    	    return null;
    	  }
    	  
    	  // Transform date of birth into "age" field.
      	if(fieldId === 'dob') {
      	  const years = moment().diff(args.dob, 'years');
      	  let age = years + ' yrs';
      		if(years < 3) {
      			age += ' ' + moment().diff(args.dob, 'months') % 12 + ' mo';
      		}
      		return {
      		  fieldId: 'age',
      		  category: field.category || "Other",
      		  name:'age',
      		  value: age
      		};
      	}
        
      	let val = args[fieldId];
    	  let name = field.name.slice(0, 14) || "";
    	  let units = field.units || "";
        
        let valid = true;
      	if(field.trackChanges && field.invalidAfter) {
        	const timeSinceUpdate = Date.now() - get(args, `dateFieldUpdated[${fieldId}].date`, 0);
          const validFor = moment.duration(field.invalidAfter, 'days').valueOf();
          valid = timeSinceUpdate < validFor;
      	}
      	if(field.type === 'date' && field.isExpiration) {
      	  valid = moment().isBefore(val);
      	}
        
    	  if(field.isFeet) {
    	    val = `${Math.floor(val / 12)}'${val % 12}"`.trim();
    	    units = "";
    	  }
    	  if(field.type === 'date') {
    	    val = moment(val).format('MM/DD/YYYY');
    	  }
    	  if(field.type === 'range') {
    	    if(isArray(val)) {
    	      if(min(val) === max(val)) {
    	        val = min(val);
    	      }
    	      else {
    	        val = `${min(val)}-${max(val)}`;
    	      }
    	    }
    	    else if(isObject(val)) {
    	      val = `${val.from}-${val.to}`;
    	    }
    	  }
    	  if(isArray(val)) {
    	    val = val.join(', ');
    	  }
    	  
    	  if(val.length > 20) {
    	    val = val.slice(0, 20).trim() + "...";
    	  }
    	  return {
    	    fieldId: fieldId,
    	    category: field.category || "Other",
    		  name: name,
    		  value: `${val} ${units}`.trim(),
    		  valid: valid,
    		};
    	
      })
      .filter(val => !!val);
  }));
  
}


