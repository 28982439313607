let handlers = {};

/**
 * Listen for events.
 * @param {String} event
 * @param {Function} handler
 * @returns {Function} The handler function. 
 */
export function on(event, handler) {
  if(typeof event === 'function') {
    handler = event;
    event = "__all";
  }
  const l = handlers[event] = handlers[event] || [];
  l.push(handler);
  return handler;
}

/**
 * Remove event listener.
 * @param {String} event
 * @param {Function} handler
 * @returns {Function} The handler function.
 */
export function off(event, handler) {
  if(!handlers[event]) {
    return;
  }
  handlers[event] = handlers[event].filter(i => i !== handler);
  return handler;
}

/**
 * Emit an event.
 * @param {String} event 
 * @param {*} payload 
 */
export function emit(event, payload) {
  (handlers.__all || []).forEach(handler => handler({type:event, ...payload}));
  (handlers[event] || []).forEach(handler => handler(payload));
}

