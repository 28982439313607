
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/functions';
import ValidationError from 'errors/validation.js';


/**
 * Reply to a notice.
 * @param {Object}        args
 * @param {Object}        args.noticeId
 * @param {Object}        args.talentId
 * @param {String}        args.message
 * @param {Object}        args.message.from
 * @param {String}        args.message.from.name
 * @param {String}        args.message.from.userId
 * @param {String}        [args.message.from.talentId]
 * @param {String}        [args.message.subject]
 * @param {String}        args.message.body
 * @param {Array<Object>} [args.message.attachments]
 * @param {String}        args.message.attachments[].cloudinary_id
 * @param {String}        args.message.attachments[].type
 * @param {String}        args.message.attachments[].format
 * @param {String}        args.message.attachments[].name
 */
export default async function replyToNotice(args={}) {

  if(!args.noticeId) {
    throw new ValidationError('noticeId');
  }

  if(!args.talentId) {
    throw new ValidationError('talentId');
  }

  if(!args.message) {
    throw new ValidationError('message');
  }

  // Get the right thread

  let threadId = await firebase.database()
    .ref(`talentNoticeThreads/${args.talentId}/${args.noticeId}`)
    .once('value').then(s => s.val());


  // todo: If no thread id, add a new thread to the parent thread. 
  
  if(!threadId) {
    throw new ValidationError('thread');
  }


  // Send a message in that thread.

  await firebase.functions().httpsCallable('sendEmail')({
    threadId, 
    message: args.message,
  });


  // Increment this thread's message count. 

  firebase.database().ref(`threads/${threadId}/hasMessages`)
    .transaction((count) => count ? count+1 : 1);

}