<script>
  import TextInput from './input-text.svelte';
  import CalendarMenu from 'ui/calendar-menu.svelte';
  import moment from 'moment';
  import { fly } from 'svelte/transition';
  
  export let value;
  export let title;



  // Value for human-readable text input.
  // Set to initial `value`, if given.
  
  let textValue = "";
  if(value) {
    textValue = moment(value).format("MM/DD/YYYY");
  }


  // When the text value changes, 
  // if the text value is a valid date, 
  // set the value to that date's timestamp.
  
  function handleTextChange(e) {
    let text = e.target.value;
    let date = moment(text);
    if(date.isValid()) {
      value = date.valueOf();
    }
  }

  // When the menu emits a change,
  // update the value,
  // and update the text value
  
  function handleMenuChange(e) {
    value = e.detail;
    textValue = moment(value).format("MM/DD/YYYY");
  }


  // Control the date picker menu.

  let isMenuOpen = false;
  function focus() {
    isMenuOpen = true;
  }
  function blur() {
    isMenuOpen = false;
  }

</script>



<div class="wrapper">
  <TextInput value="{textValue}" on:input="{handleTextChange}" {title} on:focus="{focus}" on:blur="{blur}">
    <span slot="hint">
      <slot name="hint"></slot>
    </span>
  </TextInput>

  {#if isMenuOpen}
    <div class="menu" transition:fly="{{y:-10}}">
      <CalendarMenu value="{value}" on:change="{handleMenuChange}"/>
    </div>
  {/if}
</div>



<style>
  .wrapper {
    position: relative;
  }

  .menu {
    position: absolute;
    top: 50px;
    left: 8px;
    box-shadow: var(--depth2);
    z-index: 10;
    background-color: var(--greyLight);
  }
</style>