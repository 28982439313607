<script>
  import Modal from 'ui/modal.svelte';
  import InputText from 'ui/input-text.svelte';
  import InputDate from 'ui/input-date.svelte';
  import InputHTML from 'ui/input-html.svelte';
  import InputImage from 'ui/input-unsplash.svelte';
  import InputPackage from 'ui/input-package.svelte';
  import InkWell from 'ui/inkwell.svelte';
  import updateDraft from 'actions/update-draft.js';
  import loadDraft from 'actions/load-draft.js';
  import { debounce } from 'lodash';
  import { writable } from 'svelte/store';
  import clean from 'utils/clean.js';
  import { upload, url } from 'utils/cloudinary.js';
  import postNotice from 'actions/post-notice.js';
  import AuthError from 'errors/auth.js';
  import ValidationError from 'errors/validation.js';

  let modal;

  
  
  // Initially, try to load the a draft with the key "notices".
  // Update it whenever the `value` changes.
  // Discard it when `discardDraft()` is called.
  // If a draft is saved or loaded, and hence exists, set isDraftSaved to true.
  
  let value = writable({
    cover: null,
    subject: "",
    body: "",
    dateExpires: null,
    attachments: [],
  });
  let isDraftSaved = false;
  let isDraftLoaded = false;


  // Add a casting request 

  let isCasting = false;

  function toggleCasting() {
    if(isCasting) {
      isCasting = false;
      $value.packId = null;
    }
    else {
      isCasting = true;
    }
  }


  // Load any existing draft when the modal mounts.
  // Save a draft on change. 

  const handleChange = debounce(update => {
    update = clean(update);
    updateDraft({draftId:'notices', update});
    isDraftSaved = true;
  }, 1000);

  try {
    loadDraft({draftId:'notices'}).then(draft => {
      if(draft) {
        $value = {...$value, ...draft};
        isCasting = !!$value.packId;
        isDraftSaved = true;
      }
      isDraftLoaded = true;
      value.subscribe(handleChange);
    });
  } 
  catch(error) {
    isDraftLoaded = true;
    value.subscribe(handleChange);
  }

  function discardDraft() {
    modal.close();
    updateDraft({draftId:'notices', update:null});
    isDraftSaved = false;
  }



  // Upload attachments to cloudinary

  let fileInput;

  function addAttachments(e) {
    for(let file of e.target.files) {
      let attachment = {
        progress: 1,
        date: Date.now(),
      };
      $value.attachments = [...$value.attachments, attachment];

      upload(file, {
        folder: "attachments",
        onProgress: ({progress}) => {
          attachment.progress = progress;
          $value.attachments = $value.attachments;
        },
        onComplete: (res) => {
          attachment.progress = null;
          attachment.cloudinary_id = res.cloudinary_id;
          attachment.type = res.type;
          attachment.format = res.format;
          $value.attachments = $value.attachments;
        }
      });
    }
  }

  function removeAttachment(attachment) {
    $value.attachments = $value.attachments.filter(a => a != attachment);
  }


  // Sends the notice. 

  let error = null;
  let isSending = false;
  
  async function send() {
    if(isSending) {
      return;
    }
    isSending = true;
    try {
      await postNotice($value);
    }
    catch(e) {
      if(e instanceof AuthError) {
        error = "You do not have permission to post a notice";
      }
      else if(e instanceof ValidationError) {
        error = `Please enter a ${e.field}.`;
      }
      else if(e.message == "INTERNAL") {
        error = "Something went wrong. Please try again.";
      }
      else {
        console.log('error', e);
        error = e.message;
      }
      isSending = false;
      return;
    }
    modal.send();
    isSending = false;
    updateDraft({draftId:'notices', update:null});
  }
  

</script>





{#if isDraftLoaded}
  <Modal on:close title="Notice" bind:this="{modal}">
    <div class="wrapper">
      <InputImage bind:value="{$value.cover}" title="Search Cover Images"/>
      <InputText bind:value="{$value.subject}" title="Title"/>
      <InputHTML bind:value="{$value.body}" title="Message"/>
      <div class="expires">
        <InputDate bind:value="{$value.dateExpires}" title="Expiration Date">
          <span slot="hint">The notice will not be shown on talent bulletin boards after this date.</span>
        </InputDate>
      </div>
      {#if isCasting}
        <div class="casting">
          <InputPackage bind:value="{$value.packId}">
            <span slot="hint">Talent who opt-in will be added to this package.</span>
          </InputPackage>
        </div>
      {/if}
      <div class="attachments">
        {#each $value.attachments as attachment}
          {#if attachment.progress}
            <div class="attachment">
              <i class="fad fa-spinner-third fa-spin"></i>
              <p>{attachment.progress}%</p>
              <div class="remove" on:click="{() => removeAttachment(attachment)}">
                <i class="fas fa-times-circle"></i>
              </div>
            </div>
          {:else}
            <div class="attachment">
              <img src="{url(attachment, {width:80, height:100, format:'png'})}" alt="Attachment Preview"/>
              <div class="remove" on:click="{() => removeAttachment(attachment)}">
                <i class="fas fa-times-circle"></i>
              </div>
            </div>
          {/if}
        {/each}
      </div>
      {#if error}
        <p class="error">{error}</p>
      {/if}
    </div>
    <nav>
      <div class="footer-button" on:click="{() => fileInput.click()}">
        <i class="fas fa-paperclip"></i> <span>Add Attachments</span>
      </div>
      <div class="footer-button" class:active="{isCasting}" on:click="{toggleCasting}">
        <i class="fas fa-theater-masks"></i> <span>Add Casting Request</span>
      </div>

      <div class="space"></div>

      {#if isDraftSaved}
        <div class="footer-button" on:click="{discardDraft}">
          <i class="fas fa-trash"></i> <span>Discard</span>
        </div>
      {/if}

      <div class="send-button" on:click="{send}">
        <i class="fas fa-paper-plane"></i> 
        &nbsp; 
        {isSending ? "Posting..." : "Post"}
        <InkWell/>
      </div>
    </nav>
  </Modal>
{/if}

<input type="file" bind:this="{fileInput}" multiple on:change="{addAttachments}"/>




<style>
  .wrapper {
    padding: 0px 16px 60px;
  }

  .expires {
    max-width: 360px;
  }

  .casting {
    max-width: 360px;
  }

  .attachments {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .attachment {
    position: relative;
    width: 80px;
    height: 100px;
    background-color: var(--grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 16px 16px 0
  }

  .attachment .remove {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
  }

  .attachment:hover .remove {
    display: block;
  }

  .error {
    padding: 8px 16px;
    border-radius: 2px;
    background-color: var(--error);
    color: white;
  }

  nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: var(--depth1);
  }

  nav .footer-button {
    color: var(--textLight);
    cursor: pointer;
    user-select: none;
    padding: 4px 16px 0 16px;
    line-height: 50px;
  }

  nav .footer-button:hover {
    color: var(--textDark);
  }

  nav .footer-button span {
    display: none;
  }

  nav .footer-button.active {
    color: var(--accent);
  }

  nav .space {
    flex: 1;
  }

  nav .send-button {
    position: relative;
    box-sizing: border-box;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 18px 0 16px;
    border-radius: 2px 0 2px 0;
    
    font-size: 16px;
    line-height: 54px;
    color: white;
    background-color: var(--accent);
    cursor: pointer;
  }

  nav .send-button:hover {
    color: white;
  }

  input[type=file] {
    display: none;
  }
  
  @media (min-width: 900px) {
    .wrapper {
      padding-bottom: 16px;
    }

    nav {
      position: static;
      box-shadow: none;
    }

    nav .footer-button span {
      display: inline-block;
    }
  }

</style>