<script>
  import { fly } from 'svelte/transition';
  import InkWell from 'ui/inkwell.svelte'
  import { range } from 'lodash';
  import { writable } from 'svelte/store';

  export let title = "Select";
  export let value = null;

  export let units = "";
  export let isFeet;


  // If the field has a min and max field, present all 
  // valid numbers

  export let min = 0;
  export let max;
  export let step = 1;

  let options = [];
  if(max) {
    options = range(min, max+step, step);
  }


  // When the user enters data into the text field.

  let inputValue = writable("");

  inputValue.subscribe(v => {
    if(!v) {
      value = null;
      return;
    }
    if(typeof v == "string") {
      v = v.replace(/[\D\.]/g,'');
    }
    value = Number(v);
  });


  // The menu should be open whenever the cursor is in the field.

  let isFocused = false;
  $: isMenuOpen = isFocused && options.length;

  
  // When an option is selected, 
  // - set the `value` to report to any binding parent,
  // - set the `textValue` for display,
  function select(option) {
    $inputValue = option;
  }

  
  // Validate the field.

  $: isValid = !value || ((!min || value >= min) && (!max || value <= max));

</script>




<div class="container" class:withError="{!isFocused && !isValid}" class:withValue="{$inputValue !== ''}" class:withUnits="{!!units}">
  <input type="text" bind:value="{$inputValue}" on:focus="{() => isFocused = true}" on:blur="{() => isFocused = false}"/>
  <p class="units">{units}</p>
  <p class="title">{title}</p>
  <div class="rule">
    <div class="rule-color"></div>
  </div>

  {#if isMenuOpen}
    <div class="menu" transition:fly="{{y:-10}}" on:mousedown="{e => e.preventDefault()}">
        {#each options as option}
          <div class="button" class:active="{value == option}" on:click="{() => select(option)}">
            {#if isFeet}
              {Math.floor(option / 12)}' {option % 12}" 
            {:else}
              {option} {units}
            {/if}
            <InkWell/>
          </div>
        {/each}
    </div>
  {/if}
</div>

<div class="hint">
  <slot name="hint"></slot>
  {#if !isFocused && !isValid}
    <div class="error">Please enter a {title.toLowerCase()} between {min} and {max} {units}</div>
  {/if}
</div>




<style>
  .container {
    position: relative;
    display: flex;
    align-items: flex-start;
    background-color: var(--greyLight);
  }

  input {
    flex: 1;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 8px 11px;
    border: 0;
    margin: 0;

    appearance: none;
    background-color: transparent;

    font-size: 16px;
    line-height: 18px;
  }

  .title {
    position: absolute;
    top: 3px;
    left: 8px;
    font-size: 16px;
    line-height: 18px;
    color: var(--textLight);
    pointer-events: none;
    transition: all ease-in-out 100ms;
  }

  .container.withValue .title,
  input:focus ~ .title {
    top: 0px;
    font-size: 10px;
    line-height: 13px;
  }

  .rule {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;

    display: flex;
    justify-content: center;
  }

  .rule-color {
    width: 0%;
    height: 100%;
    background-color: var(--grey);
    transition: all ease-in-out 200ms;
  }

  input:focus ~ .rule .rule-color {
    width: 100%;
  }

  .container.withError .rule .rule-color {
    width: 100%;
    background-color: var(--error);
  }

  .units {
    display: none;
    font-size: 16px;
    line-height: 18px;
    padding: 3px 8px 0 0;
    margin-bottom: 0;
    color: var(--textLighter);
    pointer-events: none;
    transition: all ease-in-out 200ms;
  }

  .container.withUnits .units {
    display: block;
  }

  .container.withValue .units,
  input:focus ~ .units {
    padding-top: 8px;
    color: var(--textLight);
  }

  .hint {
    box-sizing: border-box;
    min-height: 16px;
    padding: 2px 0 0 0;

    font-size: 11px;
    line-height: 13px;
    color: var(--textLight);
  }

  .error {
    color: var(--error);
  }
  

  .menu {
    position: absolute;
    top: 50px;
    left: 8px;
    min-width: 200px;
    max-height: 200px;
    overflow-y: auto;
    background-color: var(--greyLight);
    box-shadow: var(--depth2);
    z-index: 10;
  }

  .menu .button {
    position: relative;
    padding: 0 16px;
    font-size: 15px;
    line-height: 40px;
    color: var(--text);
    cursor: pointer;
  }

  .menu .button.active {
    background-color: var(--accent);
    color: white;
  }

  .menu .button:not(.active) :global(em) {
    background-color: var(--accentLight);
  }
</style>