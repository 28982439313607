<script>
  import firebase from 'firebase/app';
  import 'firebase/database';
  import { fly } from 'svelte/transition';
  import query from 'query/index.js';
  import { writable } from 'svelte/store';
  import InkWell from 'ui/inkwell.svelte'
  import { debounce } from 'lodash';

  export let title = "Package";
  

  // Input value is bound to `textValue` store. 
  // `search` function is called on input and populates search data.

  let textValue = writable("");
  let data;

  const search = debounce((e) => {
    data = query(`{
      packs(search:$search, limit:5)
    }`, {search: e.target.value});
  }, 200);
  

  // If an initial `value` (package id) is supplied, immediately get that pack's 
  // name and set the input value.

  export let value = "";
  if(value) {
    // depricated
    // should load package names from firestore
    firebase.database().ref(`packs/${value}/name`).once('value').then(snap => {
      let name = snap.val();
      if(!name) return;
      textValue.set(name);
    });
  }


  // When a packages is selected, 
  // - set the `value` to report to any binding parent,
  // - set the `textValue` for display,
  function select(pack) {
    value = pack.packId;
    textValue.set(pack.name);
  }


  // The menu should be open whenever there is a search and there are result.

  let isFocused = false;
  $: isMenuOpen = isFocused && !!$textValue && !!$data?.packs;
  



</script>


<div class="container" class:withValue="{!!$textValue}">
  <input type="text" bind:value="{$textValue}" on:input="{search}" on:focus="{() => isFocused = true}" on:blur="{() => isFocused = false}"/>
  <p class="title">{title}</p>
  <div class="rule">
    <div class="rule-color"></div>
  </div>

  {#if isMenuOpen}
  <div class="menu" transition:fly="{{y:-10}}" on:mousedown="{e => e.preventDefault()}">
      {#each $data?.packs || [] as pack (pack.packId)}
        <div class="button" class:active="{pack.packId == value}" on:click="{() => select(pack)}">
          {@html pack.highlighted?.name || pack.name}
          <InkWell/>
        </div>
      {:else}
        <div class="no-results">
          No Packages Found
        </div>
      {/each}
  </div>
  {/if}

</div>
<div class="hint">
  <slot name="hint"></slot>
</div>



<style>
  .container {
    position: relative;
    background-color: var(--greyLight);
  }

  input {
    flex: 1;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 8px 11px;
    border: 0;
    margin: 0;

    appearance: none;
    background-color: transparent;

    font-size: 16px;
    line-height: 18px;
  }

  .title {
    position: absolute;
    top: 3px;
    left: 8px;
    font-size: 16px;
    line-height: 18px;
    color: var(--textLight);
    pointer-events: none;
    transition: all ease-in-out 100ms;
  }

  .container.withValue .title,
  input:focus ~ .title {
    top: 0px;
    font-size: 10px;
    line-height: 13px;
  }

  .rule {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;

    display: flex;
    justify-content: center;
  }

  .rule-color {
    width: 0%;
    height: 100%;
    background-color: var(--grey);
    transition: all ease-in-out 200ms;
  }

  input:focus ~ .rule .rule-color {
    width: 100%;
  }

  .hint {
    box-sizing: border-box;
    min-height: 16px;
    padding: 2px 0 0 0;

    font-size: 11px;
    line-height: 13px;
    color: var(--textLight);
  }


  .menu {
    position: absolute;
    top: 50px;
    left: 8px;
    min-width: 200px;
    background-color: var(--greyLight);
    box-shadow: var(--depth2);
    z-index: 10;
  }

  .menu .button {
    position: relative;
    padding: 0 16px;
    font-size: 15px;
    line-height: 40px;
    color: var(--text);
    cursor: pointer;
  }

  .menu .button.active {
    background-color: var(--accent);
    color: white;
  }

  .menu .button:not(.active) :global(em) {
    background-color: var(--accentLight);
  }

  .menu .no-results {
    padding: 0 16px;
    font-size: 15px;
    line-height: 40px;
  }
</style>