import Values from 'values.js'

const colors = {
  "red":"#d32f2f",
  "redA":"#d50000",
  "pink":"#c2185b",
  "pinkA":"#c51162",
  "purple":"#7b1fa2",
  "purpleA":"#aa00ff",
  "deepPurple":"#512da8",
  "deepPurpleA":"#6200ea",
  "indigo":"#303f9f",
  "indigoA":"#304ffe",
  "blue":"#1976d2",
  "blueA":"#2962ff",
  "lightBlue":"#0288d1",
  "lightBlueA":"#0091ea",
  "cyan":"#0097a7",
  "cyanA":"#00b8d4",
  "teal":"#00796b",
  "tealA":"#00bfa5",
  "green":"#388e3c",
  "greenA":"#00c853",
  "lightGreen":"#689f38",
  "lightGreenA":"#64dd17",
  "lime":"#afb42b",
  "limeA":"#aeea00",
  "yellow":"#fbc02d",
  "yellowA":"#ffd600",
  "amber":"#ffa000",
  "amberA":"#ffab00",
  "orange":"#f57c00",
  "orangeA":"#ff6d00",
  "deepOrange":"#e64a19",
  "deepOrangeA":"#dd2c00",
  "brown":"#5d4037",
  "blueGrey":"#455a64",
  "grey":"#616161",
};


/*
Holds the current theme. 
*/
let theme = {};


/*
Update the DOM with CSS custom properties for each property of the `theme` object. 
For example: 
  
  --accent: #039be5;
  --error: #f80122;

These can be used in component css with:

  .error {
    color: var(--error, #ff0000);
  }

  .active {
    color: var(--accent);
  }

*/
function updateCSS(update={}) {
  let { container=document.body, ...props } = update;
  let style = "";
  for(let prop in props) {
    let value = props[prop];
    if(colors[value]) {
      value = colors[value];
    }
    style += `--${prop}:${value};`;
  }
  container.style = style;
}


/*
The default export has two functions: `subscribe` and `set`, which will subscribe
to updates to the theme, and set updates on the theme respectively. 

The `updateCSS` function is a subscriber. 
*/
let subscribers = [updateCSS];

function emitUpdate() {
  for(let sub of subscribers) {
    sub(theme);
  }
}

export function setTheme(newTheme) {
  theme = {...newTheme};

  // Use Material UI colors when given.
  for(let prop in theme) {
    if(colors[theme[prop]]) {
      theme[prop] = colors[theme[prop]];
    }
  }

  // If only base colors are given, calculate tints.
  ['accent'].forEach(color => {
    if(!theme[color]) {
      return;
    }
    theme[color+'Light'] = theme[color+'Light'] || new Values(theme[color]).tint(80).hexString();
    theme[color+'Dark'] = theme[color+'Dark'] || new Values(theme[color]).tint(-50).hexString();
  });

  emitUpdate();
}

export default {
  subscribe(sub) {
    subscribers.push(sub);
    sub(theme);
    return function unsubscribe() {
      subscribers = subscribers.filter(s => s != sub);
    };
  },
  set: setTheme,
};

