<script>
  import firebase from 'firebase/app';
  import 'firebase/firestore';
  import Modal from 'ui/modal.svelte';
  import Reply from './components/reply.svelte';
  import InkWell from 'ui/inkwell.svelte';
  import { url } from 'utils/cloudinary.js';
  import updateNotice from 'actions/update-notice.js';
  import moment from 'moment';
  import query from 'query/index.js';

  export let notice;

  let data = query(`{
    user {
      permissions {
        canPack
      }
      activeTalent {
        agencyId
        talentId
        name: fullName
        image
      }
    }
  }`);

  let modal;
  $: canReply = !!$data?.user.activeTalent.talentId;
  $: canExpire = !!$data?.user.permissions.canPack;
  let isReplying = false;

  function expire() {
    modal.close();
    updateNotice(notice?.noticeId, {dateExpires:moment().startOf('day').valueOf()});
  }

  let isOptedIn;

  $: if(notice?.packId && notice?.castingId && $data?.user.activeTalent.talentId) {
    firebase.firestore()
      .doc(`packages/${notice.packId}/items/${notice.castingId}/talents/${$data.user.activeTalent.talentId}`)
      .get().then(doc => {
        isOptedIn = doc.exists;
      });
  }

  function toggleOptedIn() {
    isOptedIn = !isOptedIn;
    let ref = firebase.firestore().doc(`packages/${notice.packId}/items/${notice.castingId}/talents/${$data.user.activeTalent.talentId}`);
    if(isOptedIn) {
      ref.set({date:Date.now()});
    }
    else {
      ref.delete();
    }
  }

</script>


{#if notice}
  <Modal on:close bind:this="{modal}">
    
    {#if notice.cover}
      <div class="cover">
        <img src="{notice.cover.urls.regular}" alt="Cover"/>
        <a class="caption" href="{notice.cover.links.html}" target="_blank">Photo by {notice.cover.user.name} on Unsplash</a>
      </div>
    {/if}
    <p class="subject">{notice.subject}</p>
    <div class="message-wrapper">
      <div class="message">
        {@html notice.body}
      </div>
    </div>
    <div class="attachments">
      {#each notice.attachments || [] as attachment}
        <a href="{url(attachment)}" target="_blank">
          <img src="{url(attachment, {width:80, height:120, format:'png'})}" alt="attachment" class="attachment"/>
        </a>
      {/each}
    </div>

    <nav>
      {#if canReply}
        <div class="button" on:click="{() => isReplying = !isReplying}">
          Reply
          <InkWell/>
        </div>
      {/if}
      {#if canReply && notice.packId && notice.castingId}
        <div class="button opt-in" class:active="{isOptedIn}" on:click="{toggleOptedIn}">
          {#if isOptedIn === true}
            You have opted in. 
          {:else if isOptedIn === false}
            Opt in
          {:else}
            <span>
              <i class="fad fa-spinner-third fa-spin"></i>
            </span>
          {/if}
          <InkWell/>
        </div>
      {/if}
      {#if canExpire}
        <div class="tertiary-button" on:click="{expire}">
          <i class="fas fa-trash"></i> <span>Expire this post.</span>
        </div>
      {/if}
    </nav>

    {#if isReplying}
      <Reply noticeId="{notice.noticeId}" agencyId="{notice.agencyId}"/>
    {/if}

  </Modal>
  
{/if}





<style>
  .cover {
    position: relative;
  }

  .cover img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 2px 2px 0 0;
  }

  .cover .caption {
    display: block;
    position: absolute;
    bottom: 8px;
    left: 8px;
    color: white;
    text-shadow: 0 0 4px rgba(0,0,0,0.4);
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .subject {
    font-size: 24px;
    padding: 24px 16px;
    margin: 0;
  }

  .message {
    padding: 0 16px 16px;
    margin: 0;
  }

  .attachments {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 0 0 16px;
  }

  .attachment {
    position: relative;
    width: 80px;
    height: 100px;
    object-fit: cover;
    background-color: var(--grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 16px 16px 0
  }

  nav {
    display: flex;
    align-items: center;
    padding: 0 16px 16px;
  }

  nav .button {
    position: relative;
    box-sizing: border-box;
    min-height: 53px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 32px 10px;
    border-radius: 2px;
    margin: 0 16px 0 0;
    
    font-size: 16px;
    line-height: 16px;
    color: var(--textLight);

    background-color: var(--greyLight);
    cursor: pointer;
  }

  nav .button.active {
    color: white;
    background-color: var(--accent);
  }

  nav .button.opt-in {
    width: 200px;
    text-align: center;
  }

  nav .tertiary-button {
    color: var(--textLight);
    cursor: pointer;
    user-select: none;
    padding: 10px 16px 8px 0;
  }

  nav .tertiary-button:hover {
    color: var(--textDark);
  }

</style>