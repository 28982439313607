<script>
  import firebase from 'firebase/app';
  import 'firebase/database';
  import InputText from 'ui/input-text.svelte';
  import _, { debounce } from 'lodash';
  import Fuse from 'fuse.js';
  import { url } from 'utils/cloudinary.js';
  import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();


  // Input value is bound to `textValue` store. 
  // `search` function is called on input and populates search data.

  let agencies = [];
  let data;
  
  // Load all the agencies to be searchable with fuse.
  
  let ref = firebase.database().ref('agencies')
    .orderByChild('allowTalentRegistration').equalTo(true)
    .once('value').then(snap => {
      let data = snap.val() || {};
      agencies = Object.keys(data).map(agencyId => ({...data[agencyId], agencyId}));
    });

  const search = debounce(e => {
    let query = e.target.value;
    if(!query) {
      data = null;
      return;
    }
    let options = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: [
        "agencyId",
        "meta",
        "name",
      ]
    };
    const fuse = new Fuse(agencies, options);
    const res = fuse.search(query);
    data = res.map(a => {
      let agency = a.item;
      let theme = a.item.theme || {};
      // depricated
      if(!agency.theme && agency.accentColor) {
        agency.theme = {accent:agency.accentColor};
      }
      return agency;
    });
  }, 200);


  // When an agency is selected.

  function select(agency) {
    dispatch("select", agency);
  }

  function seekingAgency() {
    dispatch("seeking-agency");
  }

</script>

<h2>Find your Agency</h2>

<InputText title="Search agencies" on:input="{search}"/>

<div class="results">
  {#each data || [] as agency (agency.agencyId)}
    <div class="agency" on:click="{() => select(agency)}">
      <img src="{url(agency.logo, {height:100})}" alt="{agency.name}"/>
      <p>{agency.name}</p>
    </div>
  {:else}
    <div class="empty">
      <p>Use the search field to find your agency.</p>
      <p>If you don't currently have representation, <span class="link" on:click="{seekingAgency}">click here</span>.</p>
      <div class="icon">
        <i class="fas fa-house-user"></i>
      </div>
    </div>
  {/each}
</div>


<style>
  h2 {
    font-size: 24px;
    padding: 0 0 16px;
    margin: 0;
    font-weight: lighter;
    color: var(--textLight);
  }

  .results {
    min-height: 200px;
  }

  .agency {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;
    border: 1px solid var(--greyLight);
    border-radius: 4px;
    position: relative;
    cursor: pointer;
  }

  .agency:hover {
    background-color: var(--greyLight);
  }

  .agency img {
    display: block;
    width: 100%;
    height: 100px;
    object-fit: contain;
    margin: 0 0 16px 0;
  }

  .agency p {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .empty {
    position: relative;
    z-index: 1;
    padding: 16px 24px 40px;
  }

  .empty .icon {
    position: absolute;
    top: 0;
    right: 8px;
    z-index: -1;
    opacity: 0.4;
    font-size: 160px;
    line-height: 16px;
    color: var(--grey);
    transform: rotate(10deg);
    filter: drop-shadow(-1px -1px 1px rgba(0,0,0,0.2)) drop-shadow(1px 1px 1px white);
  }

  .empty p {
    font-size: 18px;
    line-height: 16px;
    color: var(--textLight);
    max-width: 480px;
    padding: 0 0 8px 0;
    margin: 0;
  }

  .empty p .link {
    color: var(--accent);
    cursor: pointer;
  }

  @media (min-width: 800px) {
    .results {
      margin: 0 -16px;
    }

    .agency {
      display: inline-flex;
      width: calc(33.333% - 32px);
      margin: 0 16px 16px;
    }
  }

</style>