import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import AuthError from 'errors/auth.js';
import ValidationError from 'errors/validation.js';


/**
 * Create a new notice.
 *
 * @param {Object} data
 * @param {String} data.subject
 * @param {String} data.body
 * @param {Timestamp} data.dateExpires
 * @param {Object} data.cover
 * @param {Array<Object>} data.attachments
 * @param {String} data.packid
 *
 * @param {Object} options
 * 
 */

export default async function postNotice(data, options={}) {


  // Check access 

  if(!data.subject) {
    throw new ValidationError('subject');
  }

  if(!data.body) {
    throw new ValidationError('body');
  }

  if(!data.dateExpires) {
    throw new ValidationError('expiration date');
  }
  
  let user = firebase.auth().currentUser;
  if(!user || user.isAnonymous) {
    throw new AuthError();
  }

  let userId = user.uid;
  user = await firebase.database().ref(`users/${userId}`).once('value').then(s => s.val());
  
  if(!user) {
    throw new AuthError();
  }

  if(!user.permissions?.canPack) {
    throw new AuthError('canPack');
  }

  if(!user.agencyId) {
    throw new AuthError('agencyId');
  }

  
  // Send notice. 
  // This sends an email to all agency talents, and
  // creates the notice in firebase.

  await firebase.functions().httpsCallable('postNotice')(data);
  
}