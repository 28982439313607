import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import AuthError from 'errors/auth.js';
import ValidationError from 'errors/validation.js';

export default async function updateNotice(noticeId, update={}) {
  if(!noticeId) {
    throw new ValidationError('notice id');
  }

  let user = firebase.auth().currentUser;
  if(!user || user.isAnonymous) {
    throw new AuthError();
  }

  let userId = user.uid;
  user = await firebase.database().ref(`users/${userId}`).once('value').then(s => s.val());
  
  if(!user) {
    throw new AuthError();
  }

  if(!user.permissions?.canPack) {
    throw new AuthError('canPack');
  }

  if(!user.agencyId) {
    throw new AuthError('agencyId');
  }

  await firebase.database()
    .ref(`notices/${user.agencyId}/${noticeId}`)
    .update(update);

}