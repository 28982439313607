/**
 * Get the full name of root object. This combines `firstName` and `lastName` 
 * properties into a full name, sanitizing the result.
 * 
 * @param {Object} root
 * @param {String} [root.firstName]
 * @param {String} [root.lastName]
 * 
 * @returns {String} The full name as a string. 
 */
export function fullName(root) {
  return `${root.firstName || ""} ${root.lastName || ""}`.trim();
}