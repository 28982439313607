import gql from 'graphql-tag';
import { combineLatest, of, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators'; 
import * as resolvers from './resolvers/index.js';



/**
 * Query data by GraphQL string. 
 * @param {String} gqlString 
 * @param {Object} [variables]
 * @returns {Observable} 
 */
export default function query(gqlString="{}", variables={}) {
  const gqlQuery = gql`${gqlString}`;
  for(const definition of gqlQuery.definitions) {
    if(definition.kind === "OperationDefinition") {
      return executeSelectionSet(definition.selectionSet, {}, gqlQuery, variables, definition.operation);
    }
  }
  return of(null);
}


/**
 * 
 * @param {String} gqlString 
 * @param {Object} [variables] 
 * @returns {Promise}
 */
export function load(gqlString="{}", variables={}) {
  const gqlQuery = gql`${gqlString}`;
  for(const definition of gqlQuery.definitions) {
    if(definition.kind === "OperationDefinition") {
      let res = executeSelectionSet(definition.selectionSet, {}, gqlQuery, variables, definition.operation);
      if(!res.then && res.toPromise) {
        res = res.toPromise();
      }
      return res;
    }
  }
  return Promise.resolve(null);
}



function executeSelectionSet(selectionSet, rootValue, gqlQuery, variables, operation) {
  const res = [];
  
  selectionSet.selections.forEach(selection => {
    // Todo: directives
    // https://github.com/apollographql/apollo-client/blob/master/packages/apollo-utilities/src/directives.ts#L39
    // todo: inline fragments
    // todo: fragments
    
    if(selection.kind === "Field") {
      const args = {};
      selection.arguments.forEach(({name, value}) => {
        args[name.value] = parseValue(value, variables);
      });
      const alias = selection.alias ? selection.alias.value : selection.name.value;
      
      const fieldName = selection.name.value;

      const fieldResolver = resolvers[fieldName] || (() => of(rootValue[fieldName]));
      
      let fieldValue$;
      if(Array.isArray(rootValue)) {
        fieldValue$ = combineLatest(
          rootValue.map(itemValue => 
            fieldResolver({...itemValue, ...args})
          )
        );
      }
      else {
        fieldValue$ = fieldResolver({...rootValue, ...args});
      }
      
      // If the resolver failed, resolve to null.
      if(!fieldValue$) {
        fieldValue$ = of(null);
      }
      
      // If the resolver returned a promise.
      if(fieldValue$.then) {
        fieldValue$ = from(fieldValue$); 
      }
      
      // If the resolver returned a scalar value. 
      if(!fieldValue$.pipe) {
        fieldValue$ = of(fieldValue$);
      }
      
      // If this field itself has a selection set, map the field value to that
      // selection. 
       
      if(selection.selectionSet) {
        fieldValue$ = fieldValue$.pipe( 
          mergeMap(value => {

            // THINKING ABOUT: merging this fields `rootValue` and the value of 
            // the field to pass through. The thinking is that this way fields are 
            // passed down the heirarcy, but can be overwritten along the way. This
            // would facilitate querying a package->talent->media, and the media 
            // resolver would also be passed the package id. 
            if(Array.isArray(value)) {
              if(!value.length) {
                return of([]);
              }
              return combineLatest(
                value.map(item => 
                  // executeSelectionSet(selection.selectionSet, {...rootValue, ...item}, gqlQuery, variables);
                  executeSelectionSet(selection.selectionSet, item || {}, gqlQuery, variables, operation)
                )
              );
            }
            // return executeSelectionSet(selection.selectionSet, {...rootValue, ...value}, gqlQuery, variables);
            return executeSelectionSet(selection.selectionSet, value || {}, gqlQuery, variables, operation);
          })
        );
      }
      
      // Map the value to `{[alias]:value}`
      fieldValue$ = fieldValue$.pipe( 
        
        // TODO: use `catchError` operator to deal with errors here. 
        // You could: 
        // - just set some kind of default value, maybe something passed 
        //   into the error or into the query. 
        // - rethrow the error so that it is caught by and bubbles 
        //   up the app.
        
        map(value => ({[alias]:value})) 
      );
      
      res.push(fieldValue$);
    } 
  });
  
  return combineLatest(res).pipe(
    map(values => {
      return Object.assign.apply(Object, values);
    })
  );
}

function parseValue(value, variables={}) {
  switch(value.kind) {
    case "IntValue":
    case "FloatValue":
      return Number(value.value);
    
    case "ObjectValue":
      return value.fields.reduce((obj, {name, value}) => obj[name.value] = parseValue(value, variables), {});
    
    case "Variable": 
      return variables[value.name.value];
    
    case "ListValue":
      return value.values.map(listValue => parseValue(listValue, variables));
      
    case "NullValue":
      return null;
      
    default:
      return value.value;
  }
}