import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, of } from 'rxjs';

/**
 * 
 * @param {Object} args 
 * @param {String} args.agencyId
 */
export function agency(args) {

  if(!args.agencyId) {
    return of(null);
  }

  return new Observable(observer => {
    let ref = firebase.database().ref(`agencies/${args.agencyId}`);
    let handler = snap => {
      observer.next({
        agencyId: args.agencyId,
        ...snap.val(),
      });
    };

    ref.on('value', handler);
    return () => ref.off('value', handler);

  });
}