import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import AuthError from 'errors/auth.js';


/**
 * Save a draft. 
 * 
 * @param {Object} args
 * 
 * @param {String} args.draftId 
 * An identifier for the draft.
 * 
 * @param {Object} args.update 
 * The draft to be saved. 
 * 
 * @throws {AuthError} 
 * Thows an auth error if the user is not logged in.
 */
export default function saveDraft({draftId, update}) {
  const user = firebase.auth().currentUser;
  if(!user || user.isAnonymous) {
    throw new AuthError();
  }
  firebase.database().ref(`drafts/${user.uid}/${draftId}`).set(update);
}