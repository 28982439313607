<script>
  import firebase from 'firebase/app';
  import 'firebase/database';
  import Loading from 'ui/loading.svelte';
  import Header from './components/header.svelte';
  import Agency from './components/agency.svelte';
  import Info from './components/info.svelte';
  import {push} from 'utils/router.js';
  import query from 'query/index.js';
  import theme from 'utils/theme.js';
  import clean from 'utils/clean.js';
  import { emit } from 'utils/events.js';
  
  let themeContainer;
  let isCreating = false;
  let talent = {};
  let isSeekingAgency = null;
  let agency = null;
  let data;
  let gql = `{
    user {
      agency
      userId
      activeTalent {
        agency
      }
      permissions {
        canOwnTalents
      }
      talents
    }
  }`;
  
  // Load the user's active talent, 
  // if that talent exists and has an agency, 
  // then apply that agency.

  query(gql).subscribe(res => {
    if(!res.user.permissions.canOwnTalents) {
      push('/');
      return;
    }
    data = res;
    agency = data.user.activeTalent.agency || data.user.agency;
  });


  // Manage the new talent's agency.

  function setAgency(value) {
    agency = value?.detail;
    isSeekingAgency = false;
    $theme = {container:themeContainer, ...agency?.theme};
  }


  // Create the talent.

  async function create(e) {
    isCreating = true;

    // Add form info to talent.
    talent = {...talent, ...e.detail};

    // Set talent dates, etc.
    talent = {
      ...talent,
      agencyId: agency?.agencyId,
      isSeekingAgency: isSeekingAgency,
      dateCreated: Date.now(),
      dateUpdated: Date.now(),
      userId: data.user.userId,
    }

    // Set talent status based on agency.
    // Set requireSubscription based on agency.
    if(agency?.requireTalentSubscription || agency?.requireUserSubscription) {
      talent.statusHistory = {[Date.now()]: 'INCOMPLETE on create'};
      talent.status = 'INCOMPLETE';
      talent.requireSubscription = true;
    }
    else {
      talent.statusHistory = {[Date.now()]: 'PAID on create'};
      talent.status = 'PAID';
    }
    
    // Set approved based on talent.
    if(agency?.requireTalentApproval) {
      talent.approved = false;
    }

    // Store the talent. 
    talent = clean(talent);
    let ref = await firebase.database().ref('talents').push(talent);
    let talentId = ref.key;

    // Add the talent to the user,
    // and make this talent the user's active talent.
    await firebase.database().ref(`users/${data.user.userId}/talents/${talentId}`).set(true);
    await firebase.database().ref(`users/${data.user.userId}/activeTalent`).set(talentId);

    // If talent needs approval, add to agency for approval key.
    if(talent.agencyId && talent.approved === false && (status == 'PAID' || status == 'TRIAL')) {
      await firebase.database().ref(`talentsForApproval/${talent.agencyId}/${talentId}`).set(true);
    }

    // Emit events.
    emit('ADD_USER_TALENT', {userId:data.user.userId, talentId});
    emit('ADD_TALENT', {talentId, talent});
    
    // And push user to talent screen.
    push(`/talents/${talentId}`);
  }

</script>



<div bind:this="{themeContainer}">

  <Header/>

  <div class="wrapper">

    {#if !data} 

      <Loading></Loading>

    {:else if isCreating}

      <section>
        <Loading/>
      </section>

    {:else if agency || isSeekingAgency}

      <section>
        <Info agency="{agency}" on:submit="{create}" on:change-agency="{() => setAgency()}"/>
      </section>

    {:else}
      
      <section>
        <Agency on:select="{setAgency}" on:seeking-agency="{() => isSeekingAgency = true}"/>
      </section>

    {/if}

  </div>

</div>




<style>
  .wrapper {
    position: relative;
    width: 100%;
    max-width: 800px;
    padding-bottom: 40px;
    margin: 0 auto;
  }

  section {
    padding: 16px;
    margin: 0 8px;
    background-color: white;
    box-shadow: var(--depth1);
  }

  @media (min-width:900px) {
    section {
      padding: 32px;
    }
  }
</style>