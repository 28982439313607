/**
 * Error when validating required input.
 * @extends Error
 * 
 * Example:
 * 
 * ```
 * throw new ValidationError("talent");
 * > Error: A "talent" field is required.
 * ```
 * 
 */
 
export default class ValidationError extends Error {
  /**
   * @param {String} field The required field that was missing.
   */
  constructor(field, message="") {
    super(message || `A "${field}" field is required.`);
    
    /** @private */
    this.field = field;
  }
}