import firebase from 'firebase/app';
import 'firebase/functions';
import { emit } from 'utils/events.js';
import { isValid } from 'utils/email.js';
import ValidationError from 'errors/validation.js';

/**
 * Register a new user.
 * 
 * @param args
 * @param args.agencyId
 * @param args.invitationId
 * @param args.firstName
 * @param args.lastName
 * @param args.division
 * @param args.email
 * @param args.password
 * @param args.confirmPassword
 */

export default async function(args) {
  if(!args.email) {
    throw new ValidationError("email");
    return;
  }

  if(!isValid(args.email)) {
    throw new ValidationError("email");
    return;
  }

  if(!args.password) {
    throw new ValidationError("password");
    return;
  }

  if(args.password !== args.confirmPassword) {
    throw new Error("Your passwords don't match. Please try again.");
    return;
  }

  await firebase.functions().httpsCallable('register')(args);
  
  let res = await firebase.auth().signInWithEmailAndPassword(args.email, args.password);
  
  let userId = res.user.uid;
  let user = await firebase.database().ref(`users/${userId}`).once('value').then(s => s.val());
  
  emit('REGISTER', {user:{...user, userId}});
  emit("LOGIN", {userId, user});
  return {userId, ...user};
}