const defaultOptions = {
  format: 'png'
};


/**
 * Build a cloudinary URL for cloudinary_ids, with transformations.
 * @param {String} cloudinaryId
 * @param {Object} [options]
 * @param {Object} [options.width]
 * @param {Object} [options.height]
 * @param {Object} [options.face]
 * @param {Object} [options.grayscale]
 * @param {Object} [options.angle]
 * @param {Object} [options.format]
 */
export function url(media, options={}) {
  if(!media) {
    return "";
  }
  
  if(typeof media === 'string') {
    media = {cloudinary_id:media};
  }
  
  options = {
    ...defaultOptions,
    ...media,
    ...options,
  };
  
  let mutations = [];
  if(options.width) {
    mutations.push(`w_${options.width}`);
  }
  if(options.height) {
    mutations.push(`h_${options.height}`);
  }
  if(options.width || options.height) {
    mutations.push('c_fill');
  }
  if(options.face) {
    mutations.push('g_face');
  }
  if(options.grayscale) {
    mutations.push('e_grayscale');
    mutations.push('o_30');
  }
  if(options.angle) {
    mutations.push(`a_${options.angle}`);
  }
  mutations = mutations.join(",");
  
  if(options.type === 'video') {
    return `https://res.cloudinary.com/CLOUDINARY_CLOUD/video/upload/${mutations}/${options.cloudinary_id}.${options.format}`;
  }
  if(options.type === 'link') {
    return `https://res.cloudinary.com/CLOUDINARY_CLOUD/image/${options.format}/${mutations}/${encodeURI(options.url)}`;
  }
  return `https://res.cloudinary.com/CLOUDINARY_CLOUD/${mutations}/${options.cloudinary_id}.${options.format}`;
}


/**
 * 
 * @param {File} file 
 * @param {Object} options 
 * @param {Function} [options.onError]
 * @param {Function} [options.onComplete]
 * @param {String} [options.folder] The cloudinary folder to store the image in.
 * @returns 
 */
export function upload(file, options={}) {
  return new Promise((resolve, reject) => {
    if(!file) {
      const res = {
        status: "404",
        message: "No file given"
      };
      if(options.onError) {
        options.onError(res);
      }
      reject(res);
    }
    const formData = new FormData();
    formData.append('upload_preset', "CLOUDINARY_MEDIA_PRESET");
    formData.append("file", file);

    if(options.folder) {
      formData.append('folder', options.folder);
    }
    
    const xhr = new XMLHttpRequest();
    
    xhr.onreadystatechange = function(e) {
      if(xhr.readyState !== 4) { 
        return;
      }
      if(xhr.status !== 200) {
        if(options.onError) {
          options.onError(xhr);
        }
        reject(xhr);
        return;
      }
      let res = JSON.parse(xhr.responseText);
      if(!res || !res.public_id) {
        if(options.onError) {
          options.onError(res);
        }
        reject(res);
        return;
      }
      res = {
        cloudinary_id: res.public_id,
        type: res.resource_type,
        format: res.format,
      };
      if(options.onComplete) {
        options.onComplete(res);
      }
      resolve(res);
    };
    
    if(options.onProgress) {
      xhr.upload.addEventListener("progress", e => {
        options.onProgress({
          loaded: e.loaded,
          total: e.total,
          progress: Math.round((e.loaded * 100.0) / e.total)
        });
      });
    }
    
    xhr.open("post", `https://api.cloudinary.com/v1_1/CLOUDINARY_CLOUD/auto/upload`);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    
    xhr.send(formData);
  });
}