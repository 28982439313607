<script>
  import firebase from 'firebase/app';
  import 'firebase/database';
  import login from 'actions/login.js';
  import InputText from 'ui/input-text.svelte';
  import InkWell from 'ui/inkwell.svelte';
  import Loading from 'ui/loading.svelte';
  import { match, push } from 'utils/router.js';
  import { setTheme } from 'utils/theme.js';
  import { url } from 'utils/cloudinary.js';


  let isLoading = false;

  // If an agency is supplied, theme the form accoringly. 

  let { agencyId } = match('/login/:agencyId?');
  let logo = "/img/logo-red.png";

  if(agencyId) {
    isLoading = true;
    firebase.database().ref(`agencies/${agencyId}`).once('value').then(snap => {
      let agency = snap.val();
      if(!agency) {
        isLoading = false;
        return;
      }
      if(agency.theme) {
        setTheme(agency.theme);
      }
      /* Depricated */
      if(agency.accentColor) {
        setTheme({accent:agency.accentColor});
      }
      if(agency.logo) {
        logo = url(agency.logo, {height:120});
      }
      isLoading = false;
    });
  }


  // Login Form

  let email = "";
  let password = "";
  let error = "";
  let isLoggingIn = false;

  async function submit(e) {
    e.preventDefault();

    if(isLoggingIn) {
      return;
    }

    isLoggingIn = true;
    try {
      await login(email, password);
    }
    catch(e) {
      console.error(e);
      error = e.message;
      isLoggingIn = false;
      return;
    }

    push('/');
  }

</script>



<section>
  <form on:submit="{submit}">
    {#if isLoading} 
      <Loading/>
    {:else}
      <img src="{logo}" alt="Skybolt"/>
      <p class="welcome">WELCOME TO THE SKYBOLT PROGRAM.<br/>Please login with your email address and password.</p>
      <InputText title="Email Address" bind:value="{email}"/>
      <InputText type="password" title="Password" bind:value="{password}"/>
      <p class="error">{error}</p>
      <button type="submit">
        <InkWell/>
        {#if isLoggingIn}
          HANG TIGHT...
        {:else}
          LOGIN
        {/if}
      </button>
      <div class="link">
        <a href="/register/{agencyId || ''}">Not a member? Register here.</a>
      </div>
      <div class="link">
        <a href="/forgot-password/{agencyId || ''}">Forgot your password? Click here to reset it.</a>
      </div>
    {/if}
  </form>
</section>



<style>
  section {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(/img/onboarding-background.jpg);
    background-position: center;
    background-size: cover;
  }

  form {
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    min-height: 100%;
    padding: 24px 16px;
    background-color: white;
    text-align: center;
  }

  img {
    max-width: 200px;
    max-height: 120px;
  }

  .welcome {
    color: var(--textLight);
    margin-bottom: 32px;
  }

  .error {
    color: var(--error, #ff0000);
  }

  button {
    position: relative;
    appearance: none;
    width: 120px;
    text-align: center;
    padding: 12px 0px;
    margin-bottom: 40px;
    background-color: var(--accent);
    color: white;
    border: 0;
    border-radius: 2px;
    box-shadow: var(--depth1);
    cursor: pointer;
  }

  .link {
    display: block;
    width: 100%;
    padding-bottom: 4px;
    text-align: left;
  }

  .link a {
    color: var(--textLight);
  }

  .link a:hover {
    color: var(--accent);
  }

  @media (min-width:800px) {
    form {
      min-height: auto;
      padding: 40px 80px;
      border-radius: 2px;
      box-shadow: var(--depth3);
    }
  }
</style>