<script>
  import InkWell from 'ui/inkwell.svelte';
  
  export let accent = false;

  let style = "";

  if(accent) {
    style += "background-color: var(--accent);";
    style += "color: white;";
  }

</script>

<div class="button" on:click {style}>
  <div>
    <slot></slot>
  </div>
  <InkWell/>
</div>


<style>
  .button {
    position: relative;
    box-sizing: border-box;
    min-height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 32px 10px;
    border-radius: 2px;
    margin-bottom: 16px;
    
    font-size: 16px;
    line-height: 16px;
    color: var(--textLight);

    background-color: var(--greyLight);
    cursor: pointer;
  }

  .button:hover {
    color: var(--text);
  }
</style>