<script>
  import firebase from 'firebase/app';
  import 'firebase/database';
  import { fly } from 'svelte/transition';
  import InputText from 'ui/input-text.svelte';
  import InputDate from 'ui/input-date.svelte';
  import InputSelect from 'ui/input-select.svelte';
  import InputMultiSelect from 'ui/input-multiselect.svelte';
  import InputNumber from 'ui/input-number.svelte';
  import InputEmail from 'ui/input-email.svelte';
  import InputPhone from 'ui/input-phone.svelte';
  import InputRange from 'ui/input-range.svelte';
  import Button from 'ui/button.svelte';
  import { createEventDispatcher } from 'svelte';
  import { upload, url } from 'utils/cloudinary.js';
  import { requiredFields } from 'utils/talent-fields.js';
  import { isEmpty, isObject } from 'lodash';

	const dispatch = createEventDispatcher();


  // If an agency is given,
  // load agency talent fields, 

  export let agency;
  let fields = requiredFields;

  if(agency) {
    firebase.database()
      .ref(`agencies/${agency.agencyId}/talentFields`)
      .once('value').then(snap => {
        let data = snap.val();

        // Depricated 
        // Make sure system (isRemovable:false) fields are in there.
        let systemFields = {
          firstName: {
            name: "First Name",
            type: "text",
            category: "system",
            isEditableByTalent: true,
            isPublic: true,
            isRequired: true,
            isVisibleOnCard: true,
            isRemovable: false,
            order: -6,
          },
          lastName: {
            name: "Last Name",
            type: "text",
            category: "system",
            isEditableByTalent: true,
            isPublic: true,
            isRequired: true,
            isVisibleOnCard: true,
            isRemovable: false,
            order: -4,
          },
          dob: {
            name: "Date of Birth",
            type: "date",
            category: "system",
            isEditableByTalent: true,
            isPublic: true,
            isRequired: true,
            isVisibleOnCard: true,
            isRemovable: false,
            order: -1,
          }
        };
        data = {...systemFields, ...data};

        

        let res = [];
        for(let fieldId in data) {
          let field = {fieldId, ...data[fieldId]};
          if(field.isRequired != true || field.isEnabled === false) {
            continue;
          }
          res.push(field);
        }
        res = res.sort((a, b) => a.order - b.order);
        fields = res;
      });
  }

  function changeAgency() {
    dispatch('change-agency');
  }


  // Upload Headshots

  let fileInput;
  let isUploading = false;
  let progress = 0;
  let image;

  async function selectHeadshot(e) {
    let file = e.target.files[0];
    if(!file) {
      return;
    }

    isUploading = true;
    progress = 0;

    image = await upload(file, {
      folder: "talent",
      onProgress: e => progress = e.progress,
    });

    isUploading = false;
  }



  // When complete, dispatch a "submit" event.

  let talent = {};
  let email = "";
  let phone = "";
  let error = "";
  function create() {

    error = "";

    // todo: Validation. What is actually required? 

    for(let field of fields) {
      // If there's no value, and this field is required. 

      let fieldIsBlank = talent[field.fieldId] === undefined || 
                         talent[field.fieldId] === null || 
                         talent[field.fieldId] === "" || 
                         (isObject(talent[field.fieldId]) && isEmpty(talent[field.fieldId]));

      let fieldIsRequired = !field.condition || 
                            talent[field.condition.field] == field.condition.value || 
                            talent[field.condition.field].includes(field.condition.value);

      if(fieldIsBlank && fieldIsRequired) {
        error = `Please enter a ${field.name}.`;
        return;
      }
    }
    
    if(!email || !phone) {
      error = "Please enter an email address and phone number.";
      return;
    }

    dispatch('submit', {
      ...talent, 
      image,
      emails: [
        {
          label: "Home",
          email: email,
        }
      ],
      phones: [
        {
          label: "Home",
          number: phone
        }
      ]
    });
  }

</script>


<div class="agency">
  {#if agency}
    <img src="{url(agency.logo, {height:60})}" alt="{agency.name}"/>
    <div>
      <h2>{agency.name}</h2>
      <div class="link" on:click="{changeAgency}">Change Agency</div>
    </div>
  {:else}
    <div>
      <h2>Seeking Representation</h2>
      <div class="link" on:click="{changeAgency}">Find your Agency</div>
    </div>
  {/if}
</div>

<div class="rule"></div>

<div class="wrapper">

  <div class="headshot" on:click="{() => fileInput.click()}">
    <div class="image">
      {#if isUploading}
        <div class="loading">
          <i class="fad fa-spinner-third fa-spin"></i>
        </div>
        <p>{progress}%</p>
      {:else if image}
        <img src="{url(image, {width:200, height:200, face:true})}" alt="Headshot"/>
      {:else}
        <div class="icon">
          <i class="fas fa-user"></i>
        </div>
        <p>Upload Headshot</p>
      {/if}
    </div>
  </div>

  <div class="form">
    {#each fields as field (field.fieldId)}
      {#if !field.condition || talent[field.condition.field] == field.condition.value || talent[field.condition.field]?.includes(field.condition.value)}
        <div in:fly|local="{{y:-20}}" class="field-{field.type.toLowerCase()}">
          {#if field.type.toLowerCase() == "text"} 
            <InputText title="{field.name}" bind:value="{talent[field.fieldId]}"/>
          {/if}
          {#if field.type.toLowerCase() == "date"} 
            <InputDate title="{field.name}" bind:value="{talent[field.fieldId]}"/>
          {/if}
          {#if field.type.toLowerCase() == "select"}
            {#if field.multiple}
              <InputMultiSelect title="{field.name}" bind:value="{talent[field.fieldId]}" options="{field.options}"/>
            {:else}
              <InputSelect title="{field.name}" bind:value="{talent[field.fieldId]}" options="{field.options}"/>
            {/if}
          {/if}
          {#if field.type.toLowerCase() == "number"}
            <InputNumber title="{field.name}" bind:value="{talent[field.fieldId]}" min="{field.min}" max="{field.max}" step="{field.step}" units="{field.units}" isFeet="{field.isFeet}"/>
          {/if}
          {#if field.type.toLowerCase() == "range"}
            <InputRange title="{field.name}" bind:value="{talent[field.fieldId]}" min="{field.min}" max="{field.max}" step="{field.step}" units="{field.units}" isFeet="{field.isFeet}"/>
          {/if}
        </div>
      {/if}
    {/each}
    <div class="field-text">
      <InputEmail title="Email Address" bind:value="{email}"/>
    </div>
    <div class="field-text">
      <InputPhone title="Phone Number" bind:value="{phone}"/>
    </div>

    {#if error}
      <p class="error">{error}</p>
    {/if}
  </div>

</div>

<nav>
  <Button accent on:click="{create}">CREATE TALENT</Button>
</nav>

<input type="file" bind:this="{fileInput}" on:change="{selectHeadshot}"/>


<style>

  .agency {
    display: flex;
    align-items: center;
  }
  
  .agency img {
    margin-right: 16px;
  }

  .agency h2 {
    font-size: 24px;
    line-height: 24px;
    font-weight: lighter;
    color: var(--textLight);
    padding: 0 0 4px 0;
    margin: 0;
  }

  .agency .link {
    font-size: 13px;
    color: var(--accent);
    cursor: pointer;
  }

  .rule {
    height: 1px;
    margin: 16px 0;
    background-color: var(--grey);
  }

  .wrapper {
    display: block;
    padding-bottom: 16px;
  }

  .headshot {
    flex: 0;
    width: 200px;
    margin: 0 32px 16px 0;
    cursor: pointer;
  }

  .headshot .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    background-color: var(--grey);
    box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
  }

  .headshot .icon {
    font-size: 120px;
    color: var(--greyLight);
  }

  .headshot .loading {
    font-size: 40px;
    color: var(--greyLight);
    margin-bottom: 8px;
  }

  .headshot p {
    padding: 0;
    margin: 0;
    color: var(--greyDark);
  }

  .form {
    flex: 1;
    margin-right: -16px;
  }

  .field-text {
    width: calc(100% - 16px);
    margin-right: 16px;
  }

  .field-date, .field-number, .field-select, .field-range {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 16px);
    margin-right: 16px;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: center;;
  }

  input[type=file] {
    display: none;
  }

  .error {
    color: var(--error);
  }

  @media (min-width: 900px) {
    .wrapper {
      display: flex;
      flex-direction: row; 
      align-items: flex-start;
    }
  }
</style>