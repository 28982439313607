<script>
  export let title = "";
  export let value = "";
</script>


<div class="container" class:withValue="{!!value}">

  <div class="icon">
    <i class="far fa-search"></i>
  </div>
  
  <input type="text" bind:value="{value}" on:input/>

  <div class="clear" on:click="{() => value = ''}">
    <i class="fal fa-times-circle"></i>
  </div>

  <p class="title">{title}</p>
  <div class="rule">
    <div class="rule-color"></div>
  </div>
</div>


<style>
  .container {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    background-color: var(--greyLight);
  }

  .icon {
    flex: 0 0 auto;
    box-sizing: border-box;
    width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;

    color: var(--textLight);
    font-size: 16px;
    line-height: 16px;
  }

  .clear {
    flex: 0 0 auto;
    box-sizing: border-box;
    width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;

    color: var(--textLight);
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;

    transition: all 200ms ease-in-out;
    opacity: 0;
    transform: translateX(10px);
  }

  .container.withValue .clear {
    opacity: 1;
    transform: translateX(0px);
  }

  input {
    flex: 1;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 8px 11px 0;
    border: 0;

    appearance: none;
    background-color: transparent;

    font-size: 16px;
    line-height: 18px;
  }

  .title {
    position: absolute;
    top: 6px;
    left: 32px;
    font-size: 16px;
    line-height: 18px;
    color: var(--textLight);
    pointer-events: none;
    transition: all ease-in-out 100ms;
  }

  .container.withValue .title,
  input:focus ~ .title {
    top: 0px;
    font-size: 10px;
    line-height: 13px;
  }

  .rule {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;

    display: flex;
    justify-content: center;
  }

  .rule-color {
    width: 0%;
    height: 100%;
    background-color: var(--grey);
    transition: all ease-in-out 200ms;
  }

  input:focus ~ .rule .rule-color {
    width: 100%;
  }
</style>