/**
 * Error when an unauthorized action is taken.
 * @extends Error
 * 
 * _example_
 * 
 * ```
 * if(!user.permissions.canPack) {
 *   throw new AuthError('canPack');
 * }
 */
 
export default class AuthError extends Error {
  /**
   * @param {String} permission The required permission that was missing.
   */
  constructor(permission) {
    if(!permission) {
      super("Not logged in.");
    }
    else {
      super(`Missing "${permission}" permission.`);
    }
    
    /** @private */
    this.permission = permission;
  }
}